import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CKEditor } from 'ckeditor4-react';
import Button from '@mui/material/Button';
import parse from "html-react-parser";
import { useCreateSummaryMutation } from '../../services/bookItems';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
function AddSummary({ getSummaryModal, setSummaryModal, langDirection, itemId, language, book, summaries }) {
  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const [createSummary, summariesresult] = useCreateSummaryMutation()

  const [getSummaryTitle, setSummaryTitle] = useState("")
  const [getSummaryImage, setSummaryImage] = useState("")
  const [getSummary, setDetailss] = useState("")
  const getCLickDetails = (evt) => {
    const data = evt.editor.getData();
    setDetailss(data);
  }

  const createSummaryData = {
    summary: getSummary,
    id: itemId,
    title: getSummaryTitle,
    image: getSummaryImage,
    language: language,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  }


  function closeSummaryModal() {
    setSummaryModal(false)
  }

  useEffect(() => {
    if (summariesresult.isSuccess) {
      setSummaryModal(false)
      setSummaryTitle("")
      setSummaryImage("")
      setDetailss("")
    }
  }, [summariesresult]);

  return (
    <>
      <Modal className="new-book-item" show={getSummaryModal} onHide={closeSummaryModal} animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Add New Summary</Modal.Title>
        </Modal.Header> */}
        <Modal.Body dir={langDirection}>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeSummaryModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>New LiT</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Title</label>
                <input type="text" className="form-control"
                  onChange={(e) => setSummaryTitle(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Image URL</label>
                <input type="text" className="form-control"
                  onChange={(e) => setSummaryImage(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Book Summary</label>
                <CKEditor
                  data={book?.language === 'ar' || book?.language === 'urdu' ? `<h2 style="text-align: center;"> <strong> ليت ${summaries?.length + 1} </strong></h2><br />` : `<h2 style="text-align: center;"> <strong> LiT ${summaries?.length + 1} </strong></h2><br />`}
                  config={{
                    extraPlugins: "justify,showblocks",
                    toolbar: [
                      ['Source'],
                      ['Styles', 'Format', 'Font', 'FontSize'],
                      ['Bold', 'Italic'],
                      ['Undo', 'Redo'],
                      ['EasyImageUpload'],
                      ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                      ['Maximize']
                    ],
                    height: '220px',
                    allowedContent: true,
                    direction: langDirection,
                    language: book?.language === 'ar' || book?.language === 'urdu' ? 'ar' : 'en',
                    pasteFromWordRemoveFontStyles: false
                  }}
                  id="template_html"
                  onChange={getCLickDetails}
                  initData={parse(book?.language === 'ar' || book?.language === 'urdu' ? `<h2 style="text-align: center;"> <strong> ليت ${summaries?.length + 1} </strong></h2><br />` : `<h2 style="text-align: center;"> <strong> LiT ${summaries?.length + 1} </strong></h2><br />`)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: 'right' }}>
                <Button
                  style={{ background: '#2c2245cf', color: '#f7f7f7' }}
                  disabled={summariesresult.isLoading ? true : false}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={() => { createSummary(createSummaryData) }}>
                  {summariesresult.isLoading ? 'Loading...' : 'Submit'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddSummary;
