import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CKEditor } from 'ckeditor4-react';
import Button from '@mui/material/Button';
import parse from "html-react-parser";
import { useUpdateSummaryMutation } from '../../services/bookItems';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
function UpdateSummary({ setSummaryUpdateModal, getSummaryUpdateModal, getSummaryUpdateData, langDirection, language, book }) {
  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);

  const [updatesPost, updatesresult] = useUpdateSummaryMutation()

  const [getUpdateTitle, setUpdateTitle] = useState("");
  const [getUpdateImage, setUpdateImage] = useState("");
  const [getUpdateSummary, setUpdateSummary] = useState("")
  const getClickDescription = (evt) => {
    const data = evt.editor.getData();
    setUpdateSummary(data);
  }

  const updateSummaryData = {
    summary: getUpdateSummary ? getUpdateSummary : getSummaryUpdateData?.summary,
    title: getUpdateTitle ? getUpdateTitle : getSummaryUpdateData?.title,
    image: getUpdateImage ? getUpdateImage : getSummaryUpdateData?.image,
    id: getSummaryUpdateData?.updateId,
    language: language,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  }


  function handleOpenclose() {
    setSummaryUpdateModal(false)
  }



  useEffect(() => {
    if (updatesresult.isSuccess) {
      setSummaryUpdateModal(false)
      setUpdateTitle("")
      setUpdateSummary("")
    }
  }, [updatesresult]);



  return (
    <>
      <Modal className="new-book-item" show={getSummaryUpdateModal} onHide={handleOpenclose} animation={false}>
        <Modal.Body dir={langDirection}>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleOpenclose}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Summary</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Title</label>
                <input type="text" className="form-control" value={getUpdateTitle ? getUpdateTitle : getSummaryUpdateData?.title}
                  onChange={(e) => setUpdateTitle(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Image URL</label>
                <input type="text" className="form-control" value={getUpdateImage ? getUpdateImage : getSummaryUpdateData?.image}
                  onChange={(e) => setUpdateImage(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Book Summary</label>
                <CKEditor config={{
                  // extraPlugins: 'codemirror,dragresize',
                  extraPlugins: "justify,showblocks",
                  toolbar: [
                    ['Source'],
                    ['Styles', 'Format', 'Font', 'FontSize'],
                    ['Bold', 'Italic'],
                    ['Undo', 'Redo'],
                    ['EasyImageUpload'],
                    ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                    ['Maximize']
                  ],
                  height: '220px',
                  allowedContent: true,
                  direction: langDirection,
                  language: book?.language === 'ar' || book?.language === 'urdu' ? 'ar' : 'en',
                  pasteFromWordRemoveFontStyles: false
                }} id="template_html" data={getSummaryUpdateData?.summary ? getSummaryUpdateData?.summary : getUpdateSummary} onChange={getClickDescription} initData={parse(getSummaryUpdateData?.summary ? getSummaryUpdateData?.summary : getUpdateSummary)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: 'right' }}>
                <Button
                  style={{ background: '#2c2245cf', color: '#f7f7f7' }}
                  disabled={updatesresult.isLoading ? true : false}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={() => { updatesPost(updateSummaryData) }}>
                  {updatesresult.isLoading ? 'Loading...' : 'Submit'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateSummary;
