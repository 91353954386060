import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Modal, Spinner } from 'react-bootstrap'
import './index.scss';
import TableSkeleton from "./TableSkeleton";
import SearchIcon from "@material-ui/icons/Search";
import Loader from '../../Helper/Loader';
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetAllSearchBookQuery,
  useGetAllBooksQuery,
  useCreateCoverMutation,
  useUpdateBookCoverMutation,
  useCreateBookItemMutation,
  useUpdateBookItemMutation,
  useDeleteBookMutation
} from '../../services/books';
import NewBookCover from './NewBookCover';
import UpdateBookCover from './UpdateBookCover';
import NewBookItem from './NewBookItem';
import IconButton from '@mui/material/IconButton';
import { Tag, Switch as Clickable } from "antd";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import EditIcon from '@mui/icons-material/Edit';

function Search() {


  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const location = useLocation();
  const history = useHistory();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const [getSearchId, setSearchId] = useState("")
  const { name } = useParams();
  const getLanguage = new URLSearchParams(location.search).get('lang') || ''

  const booksInfo = useGetAllSearchBookQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    name: name,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const books = booksInfo?.data && booksInfo?.data?.books;
  const pagination = booksInfo?.data && booksInfo?.data?.pagination;

  // create new book cover
  const [createBookCover, bookCoverResult] = useCreateCoverMutation()

  const [getNewBookModal, setNewBookModal] = useState(false)
  function newBookModal(data) {
    setNewBookModal(data.status)
  }
  function closeNewBookModal() {
    setNewBookModal(false)
  }

  // update book item states
  const [updateItemState, updateItemStateResult] = useUpdateBookItemMutation()
  const [deleteBook, deleteresult] = useDeleteBookMutation()

  // Update book cover modal
  const [updateResult, updateResultData] = useUpdateBookCoverMutation()
  const [getUpdateBookModal, setUpdateBookModal] = useState(false)
  const [getUpdateData, setUpdateData] = useState('')

  function updateBookModal(data) {
    setUpdateBookModal(data.status)
    setUpdateData(data)
  }
  function closeUpdateBookModal() {
    setUpdateBookModal(false)
  }

  // create new book item cover
  const [createBookItem, createBookItemResult] = useCreateBookItemMutation()
  const [getBookItemModal, setBookItemModal] = useState(false)
  const [getBookCoverId, setBookCoverId] = useState()
  const [getItemLanguages, setItemLanguages] = useState([])
  function bookItemModal(data) {
    setBookItemModal(data.status)
    setBookCoverId(data.id)
    setItemLanguages(data.itemLanguages)
  }
  function closeBookItemModal() {
    setBookItemModal(false)
  }

  useEffect(() => {
    if (bookCoverResult?.isSuccess) {
      setNewBookModal(false)
    }
    if (updateResultData?.isSuccess) {
      setUpdateBookModal(false)
    }
    if (createBookItemResult?.isSuccess) {
      setBookItemModal(false)
    }
  }, [
    bookCoverResult && bookCoverResult,
    updateResultData && updateResultData,
    createBookItemResult && createBookItemResult
  ]);



  // view book items Modal
  const [getBookItemsModal, setBookItemsModal] = useState(false)
  const [getBookItemId, setBookItemId] = useState()
  function viewBookItemsModal(data) {
    setBookItemsModal(data.status)
    setBookItemId(data.id)
  }
  function closeBookItemsModal() {
    setBookItemsModal(false)
  }


  function getlabel(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }
  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 80%) !important',
      boxShadow: 'none !important',
      minWidth: '140px',
      maxWidth: '100%'
    }),
    option: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer'
    }),
    menu: styles => ({
      ...styles,
      width: '100%'
    })
  };

  function getFullLanguage() {
    if (getLanguage === 'en') {
      return 'English'
    } else if (getLanguage === 'ar') {
      return 'Arabic'
    } else if (getLanguage === 'desi') {
      return 'Desi'
    } else if (getLanguage === 'urdu') {
      return 'Urdu'
    } else {
      return 'Language'
    }
  }

  return (
    <div className="tabel_cards books-page">

      {/* <ViewBookItemsModal
        show={getBookItemsModal}
        close={closeBookItemsModal}
        data={getBookItems}
        updateBookItemState={updateBookItemState}
      /> */}


      <NewBookCover
        getNewBookModal={getNewBookModal}
        closeNewBookModal={closeNewBookModal}
        error={bookCoverResult?.error?.data?.error}
        isLoading={bookCoverResult?.isLoading}
        isSuccess={bookCoverResult?.isSuccess}
        createBookCover={createBookCover}
      />

      <UpdateBookCover
        show={getUpdateBookModal}
        close={closeUpdateBookModal}
        data={getUpdateData}
        error={updateResultData?.error?.data?.error}
        isLoading={updateResultData?.isLoading}
        isSuccess={updateResultData?.isSuccess}
        updateResult={updateResult}

      />

      <NewBookItem
        show={getBookItemModal}
        close={closeBookItemModal}
        error={createBookItemResult?.error?.data?.error}
        isLoading={createBookItemResult?.isLoading}
        isSuccess={createBookItemResult?.isSuccess}
        createBookItem={createBookItem}
        getBookCoverId={getBookCoverId}
        getItemLanguages={getItemLanguages}
      />


      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">
             
                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={booksInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
                <div className="tabelOverflow">
                  {/* <div className={isLoadingTable && 'table-update'}></div> */}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Languages</th>
                        <th style={{ width: '1px' }}>Created By</th>
                        <th style={{ width: '1px' }}>Updated At</th>
                        <th className="sticky-column" style={{ textAlign: 'right', width: '1px' }}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {booksInfo?.isLoading &&
                          <TableSkeleton />
                        }
                      </>
                      {books?.map((item, i) =>
                        <>
                          <tr key={i}>
                            <td style={{ width: '1px' }}>{item.id}</td>
                            <td style={{ position: 'relative' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                  onClick={() => updateBookModal({
                                    "status": true,
                                    "title": item.title,
                                    "updateId": item.id
                                  })}
                                >
                                  <EditIcon style={{ fontSize: '12px' }} />
                                </IconButton>
                              <div className="bhref"
                                onClick={() => viewBookItemsModal({
                                  status: true,
                                  id: item.id
                                })}
                                >{item.title}</div>
                                </div>
                            </td>
                            <td style={{ width: '1px' }}>
                              {item.items?.map((items, i) =>
                                <Link to={`/book-details/${item.id}/${items.id}/${items.language}/${items.title.replace(/\s+/g, '-').toLowerCase()}`}>
                                  <Tag className="booksTag" color="#55acee">
                                    {items.language}
                                  </Tag>
                                </Link>
                              )}
                            </td>
                            <td style={{ width: '1px' }}>{item.user?.email}</td>
                            <td style={{ width: '1px' }}>{item.updatedAt}</td>
                            <td className="sticky-column" style={{ width: '1px' }}>
                              <div className="manage-buttons" style={{ justifyContent: 'right' }}>
                         
                                <IconButton
                                  onClick={() => bookItemModal({ status: true, id: item.id, itemLanguages: item.items })}
                                >
                                  <LibraryAddIcon />
                                </IconButton>

                                <a onClick={e => e.preventDefault()}>
                                  <IconButton
                                    onClick={() => {
                                      if (window.confirm('Are you sure to delete this record?')) deleteBook({
                                        id: item.id,
                                        userId: userId,
                                        userToken: userToken,
                                        userRole: userRole
                                      })
                                    }}
                                  >
                                    {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ? <Loader /> :
                                      <RemoveCircleIcon style={{ color: '#e66565' }} />
                                    }
                                  </IconButton>
                                </a>
                              </div>
                            </td>
                          </tr>


                          <Modal className={`viewBookItemsModal`} show={getBookItemId === item.id ? getBookItemsModal : false} onHide={closeBookItemsModal} animation={false}>
                            <Modal.Header closeButton>
                              <Modal.Title>Available book listing</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="tabelOverflow" style={{ overflow: 'auto', fontSize: '13px' }}>

                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>ID</th>
                                          <th>Image</th>
                                          <th>Title</th>
                                          <th>Authors</th>
                                          <th>Tags</th>
                                          <th>Language</th>
                                          <th className="sticky-column">
                                            Track
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>



                                        {item.items?.map((items, i) =>
                                          <tr key={i}>
                                            <td>
                                              {items.id}
                                            </td>
                                            <td>
                                              <div>
                                                <img src={items.image ? `https://dj70nht8amdum.cloudfront.net/${items.book_id}/${items.language}/${items.id}/${items.image}` : '/No-Image-Available-1.png'} width="70px" />
                                              </div>
                                            </td>
                                            <td style={{ position: 'relative' }}>
                                              <Link to={`/book-details/${items.book_id}/${items.id}/${items.language}/${items.title.replace(/\s+/g, '-').toLowerCase()}`}>
                                                <div style={{ whiteSpace: 'normal', width: '320px' }}>{items.title}</div>
                                              </Link>
                                            </td>
                                            <td>
                                              {items.authors}
                                            </td>
                                            <td>
                                              {items.tags}
                                            </td>
                                            <td>
                                              {items.language}
                                            </td>
                                            <td className="sticky-column">
                                              <div className="manage-buttons">
                                                <Clickable
                                                  defaultChecked={items.state ? true : false}
                                                  onClick={() => updateItemState({
                                                    state: items.state ? '' : 'listed',
                                                    id: items.id,
                                                    language: items.language,
                                                    userId: userId,
                                                    userToken: userToken,
                                                    userRole: userRole
                                                  })}
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                        }
                                      </tbody>
                                    </table>
                                    {item.items?.length === 0 &&
                                      <h3 style={{ textAlign: 'center', fontSize: '1.2rem', color: '#1b21384f', padding: '2rem' }}>
                                        Not Available!
                                      </h3>}
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>

                        </>



                      )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
