import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import CloseIcon from "@material-ui/icons/Close";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";


function ImageUpdate({ setUpdateImage, imageresult, updateBookImage, setUploadingStatus }) {

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    setUploadingStatus(status)
    setUpdateImage(file)
  };


  const [getCompleted, setCompleted] = useState("");

  const config = {
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setCompleted(percentCompleted);
    }
  }

 
  // const Preview = ({ meta }) => {
  //   const { name, percent, status, size, duration } = meta
  //   var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
  //   let fSize = size;
  //   let i;
  //   i = 0; while (fSize > 900) { fSize /= 1024; i++; }
  //   return (
  //     <>
  //       <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
  //         <div style={{width: '100%'}}>
  //           <div>
  //             <div>{name}</div>
  //             <div style={{ width: '100%' }}><LinearProgress style={{ height: '6px' }} variant="determinate" value={percent} /></div>
  //           </div>
  //           <div>{status}</div>
  //           <div>{(Math.round(fSize * 100) / 100) + ' ' + fSExt[i]}</div>
  //           <div>{duration}</div>
  //         </div>
  //       </div>
  //     </>
  //   )
  // }







  return (
    <>
      <div className="row">
        <br /><br />
        <div className="col-md-12">
          <Dropzone
            // getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={updateBookImage}
            // accept="image/*,audio/*,video/*"
          // PreviewComponent={Preview}
          // inputContent="Drop Files (Custom Preview)"
          // disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
          />
        </div>

        <div className="col-md-12">
          {imageresult.isLoading &&
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Spinner style={{ width: '1.2rem', height: '1.2rem', fontSize: '7px', marginRight: '10px' }} animation="border" />
              <h5 style={{ margin: 0, fontSize: '1rem' }}>uploading... don't close or reload the browser </h5>
            </div>
          }
        </div>



        {/* <div className="col-md-12">
          <div className="form-group">
            <input type="file" className="form-control"
              onChange={(e) => setUpdateImage(e.target.files[0])} />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group" style={{ textAlign: 'center' }}>
            {imageresult.isLoading ?
              <>
                <Spinner style={{ width: '2rem', height: '2rem', fontSize: '7px', marginBottom: 10 }} animation="border" />
                <h5>uploading... don't close or reload the browser </h5>
              </>

              :
              <Button
                style={{ width: '100%' }}
                disabled={imageresult.isLoading ? true : false}
                color="info"
                variant="contained"
                size="large"
                onClick={(() => updateBookImage())}>
                Update
              </Button>
            }
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ImageUpdate;
