import React, { Component, useState } from 'react';
import { Redirect, Link } from "react-router-dom";
import './index.scss';
import { useSelector } from 'react-redux'
function LanguageSelect(props) {
  const { userId, userToken, userEmail, userName, status, userRole, languageCode } = useSelector((state) => state.auth);
  const Cmplng = props.cmplng;
  return (
    <div>
      {userToken && userId ? <Cmplng /> : <Redirect to="login"></Redirect>}
    </div>
  );
}

export default LanguageSelect;
