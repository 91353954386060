import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
// import { Line, Circle } from 'rc-progress';
import './index.scss';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Axios from "axios";
import { CKEditor } from 'ckeditor4-react';
import parse from "html-react-parser";
import { useSelector } from 'react-redux'

function Profile() {

  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, } = useForm();

  const location = useLocation();
  const [isLoading, setLoading] = useState(false)
  const [isLoadingCreate, setLoadingCreate] = useState(false)
  const [isLoadingUpdate, setLoadingUpdate] = useState(false)
  const [isLoadingdoc, setLoadingdoc] = useState(false)
  const [show, setShow] = useState(false);
  const [getSuccessMsg, setSuccessMsg] = useState("");



  const initProfile = {
    user: []
  };
  const [getUser, setProfile] = useState(initProfile)
  const [getMessageError, setMessageError] = useState("")
  const [getMessage, setMessage] = useState("")

  function getProfile() {
    setLoading(true);
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_CONNECT}/user?id=${userId}`,
      headers: {
        'X-User-Token': userToken,
        'X-User-Id': userId,
        'X-User-Role': userRole,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    Axios(config)
      .then((response) => {
        if (response.status === 200) {
          setProfile({
            user: (response.data.user[0] ? response.data.user[0] : null)
          });
          setLoading(false)
        } else {
          response.json().then((resp) => {
            setMessageError(resp.message)
          })
          setMessage('error')
          setLoading(false)
        }
        setLoading(false)
      })
  }
  useEffect(() => {
    getProfile();
  }, []);




  const [getMeesage, setMeesage] = useState("")
  const [getError, setError] = useState("")
  const [getPasswordChangeLoading, setPasswordChangeLoading] = useState(false)




  const [getUpdateProfileLoading, setUpdateProfileLoading] = useState(false)
  function updateProfile(data) {
    setUpdateProfileLoading(true);
    let updateData = {
      "update_name": (data.update_name === '' ? getUser.user.name : data.update_name),
      "update_mobile": (data.update_mobile === '' ? getUser.user.mobile : data.update_mobile),
      "update_address": (data.update_address === '' ? getUser.user.address : data.update_address),
      "update_user_id": userId
    }
    fetch(`${process.env.REACT_APP_API_CONNECT}/users`, {
      method: "POST",
      headers: {
        'X-User-Token': userToken,
        'X-User-Id': userId,
        'X-User-Role': userRole,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updateData)
    }).then((response) => {
      if (response.status === 201) {
        getProfile();
        setUpdateProfileLoading(false)
      } else {
        response.json().then((resp) => {
          setMeesage(resp.message)
        })
        setError("error")
        getProfile();
        setUpdateProfileLoading(false)
      }

    })
  }


  return (
    <div className="Profile-page">


      {isLoading ? <div className="loadingBorder">&nbsp;</div> : ''}

      <div className="container">
        <div className="row">
          <div className="col-md-7">  
            <div className="change-password-card">
              <div className="row company_detailsRow">
                <div className="col-md-12">
                <h4> Update profile!</h4>
                  {getError === 'error' ? <div className="col-md-12" style={{ textAlign: "center" }}>
                    <div className="form-group">
                      <small className="alert alert-danger">{getMeesage}</small>
                    </div>
                  </div> : null}
                  {getError === 'success' ? <div className="col-md-12" style={{ textAlign: "center" }}>
                    <div className="form-group">
                      <small className="alert alert-success">{getMeesage}</small>
                    </div>
                  </div> : null}
                </div>
                <div className="col-md-12">

                  <form onSubmit={handleSubmit(updateProfile)}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Name</label>
                          <input type="text" defaultValue={getUser.user.name} className="form-control" id="update_name" aria-invalid={errors.update_name ? "true" : "false"} {...register('update_name')} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Mobile</label>
                          <input type="text" defaultValue={getUser.user.mobile} className="form-control" id="update_mobile" aria-invalid={errors.update_mobile ? "true" : "false"} {...register('update_mobile')} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" defaultValue={getUser.user.address} className="form-control" id="update_address" aria-invalid={errors.update_address ? "true" : "false"} {...register('update_address')} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          {getUpdateProfileLoading ? <Button variant="info" size="sm" className="hghtbtn" disabled>Updating</Button> : <Button variant="info" size="sm" className="hghtbtn" type="submit">Update</Button>}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
