import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function UsersSkeleton() {
  return (
    <>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td> 
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td> 
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td> 
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
    </>
  )
}

export default UsersSkeleton;