import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CKEditor } from 'ckeditor4-react';
import Button from '@mui/material/Button';
import parse from "html-react-parser";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
function ViewSummary({ getViewSummaryModal, langDirection, getSummaryData, setViewSummaryModal, langTextAlign }) {
  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);


  function clodeViewSummary() {
    setViewSummaryModal(false)
  }

  return (
    <>
      <Modal className="new-book-item" show={getViewSummaryModal} onHide={clodeViewSummary} animation={false}>
        <Modal.Body dir={langDirection} style={{ textAlign: langTextAlign }}>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={clodeViewSummary}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Summary</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h6><strong>{getSummaryData?.title}</strong></h6>
              <div className="form-group">
                <div className="HomePage_static_description"
                  dangerouslySetInnerHTML={{
                    __html: getSummaryData?.summary
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewSummary;
