import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useIntegratedMutation } from '../../services/integrated';
// import Pagination from "../Pagination";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { message, Tag, Menu, Dropdown, Switch as Clickable } from "antd";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Moment from 'moment';
import Select from 'react-select';
import Loader from '../../Helper/Loader';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
const KEY = "ADD_PRODUCTS";
function AI() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')

  const othersUserData = {
    name: getName,
    username: getUsername,
    reference: getReference
  }



  const [createUser, createresult] = useIntegratedMutation()


 
  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }


  function othersEModal() {
    setOthersModal(true)
  }
  function closeOthersModal() {
    setOthersModal(false)
  }


  const [getUserUpdateModal, setUserUpdateModal] = useState(false)
  const [getUserUpdateData, setUserUpdateData] = useState()
  const [getUserRole, setUserRole] = useState('')
  function updateUserModal(data) {
    setUserUpdateModal(data.status)
    setUserUpdateData(data)
  }
  function closeUserUpdateModal() {
    setUserUpdateModal(false)
  }

  const bookItemData = {
    user_role: getUserRole,
    id: getUserUpdateData?.id,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }

 


  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto'
    }),
    option: styles => ({
      ...styles,
      width: 'auto'
    }),
    menu: styles => ({
      ...styles,
      width: 'auto'
    })

  };

  function displayUserRole(role) {
    let name;
    if (role === 'admin') {
      name = 'Master'
    } else if (role === 'task') {
      name = 'Task'
    } else if (role === 'default') {
      name = ''
    } else {
      name = role
    }
    return name;

  }



  return (
    <div className="tabel_cards">




      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>

              <div className="storeCardB">

                <div className="tabelOverflow">
                  <form onSubmit={handleSubmit(createUser)}>

                    <div className="row" style={{ justifyContent: 'center' }}>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea style={{ height: '250px', borderRadius: 0 }} className='form-control' id="content" aria-invalid={errors.content ? "true" : "false"}
                            {...register('content', { required: true })}> </textarea>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <Button
                            style={{ width: '100%', background: '#2c2245cf', color: '#f7f7f7' }}
                            disabled={createresult.isLoading ? true : false}
                            color="info"
                            variant="contained"
                            size="medium"
                            type="submit">
                            {createresult.isLoading ? 'Loading...' : 'Submit'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div style={{ padding: '20px' }}>
                  {createresult?.error &&

                    <div style={{ color: 'tomato' }}>
                      {createresult?.error?.data}
                    </div>
                  }



                  {
                    createresult?.isLoading ?
                    <Loader /> :
                    createresult?.data?.data
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AI;
