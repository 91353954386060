import React, { useState, useEffect, Fragment } from 'react';
import "antd/dist/antd.min.css";
import './index.scss';
import Hls from "hls.js";
import plyr from "plyr";
import "plyr/dist/plyr.css";



const controls = `
<div class="plyr__controls voice-controls" style="display: block;">
  <div>
    <div class="plyr__progress">
      <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>
    <div style="display: flex; align-items: center; justify-content: space-between;">
    <div class="plyr__time plyr__time_voice plyr__time--current" aria-label="Current time">00:00</div>
    <div class="plyr__time plyr__time_voice plyr__time--duration" aria-label="Duration">00:00</div>
  </div>
  </div>
  <div style="display: flex; align-items: center; justify-content: space-between;">
    <div style="display: flex; align-items: center;">
    
      <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
      </button>
    
    </div>
    <div style="display: flex; align-items: center;">
    <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
      </button>
      <div class="plyr__volume">
        <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
      </div>
    </div>
  </div>
</div>
`;

function AudioPlayer({ book_id, language, book_item_id, id, media, directory, dir }) {
  const src = `https://d1ozu9ddqfby9k.cloudfront.net/${dir}/${book_id}/${language}/${book_item_id}/${id}/${media}/${directory}_${id}_master.m3u8`;


  const video = document.getElementById(`player${id}`);
  const defaultOptions = {
    controls: controls,
    seekTime: 10
  };


  const hls = new Hls();
  hls.loadSource(src);
  hls.attachMedia(video);

  hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
    const availableQualities = hls.levels.map((l) => l.height);
    window.hls.currentLevel = -1
    availableQualities.unshift(0);
    defaultOptions.quality = {
      default: 0, //Default - AUTO
      options: availableQualities,
      forced: true,
      onChange: (e) => updateQuality(e)
    };
    hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
      var span = document.querySelector(
        ".plyr__menu__container [data-plyr='quality'][value='0'] span"
      );
      if (hls.autoLevelEnabled) {
        span.innerHTML = `Auto (${hls.levels[data.level].height}p)`;
      } else {
        span.innerHTML = `Auto`;
      }
    });
    new plyr(video, defaultOptions);
  });
  hls.attachMedia(video);
  window.hls = hls;

  function updateQuality(newQuality) {
    if (newQuality === 0) {
      window.hls.currentLevel = -1; //Enable AUTO quality if option.value = 0
    } else {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    }
  }


  return (
    <>
      {directory === 'audio' ?
        <audio
          style={{ width: '100%', minHeight: '117px', maxHeight: '117px' }}
          className="audio-plyr"
          id={`player${id}`}
          playsinline
          webkit-playsinline
          autoPlay={false}
        >
        </audio>
        :
        <video
          style={{ width: '100%', minHeight: '117px', maxHeight: '117px' }}
          controls
          className="audio-plyr"
          id={`player${id}`}
          playsinline
          webkit-playsinline
          autoPlay={false}
        >
        </video>
      }
    </>
  );


}


export default AudioPlayer;
