import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import TableSkeleton from "./TableSkeleton";
import { Switch as Clickable } from "antd";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import { useGetAllCategoryQuery, useCreateCategoryMutation, useUpdateCategoryMutation, useUpdateImageMutation, useDeleteMediaMutation } from '../../services/categories';
import 'react-h5-audio-player/lib/styles.css';
import New from "./New";
import Update from "./Update";
import ImageUpdate from "./ImageUpdate";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';


function Categories() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const getLanguage = new URLSearchParams(location.search).get('language') || 'en'

  let languageGet
  if (getLanguage === null) {
    languageGet = '';
  } else {
    languageGet = `&language=${getLanguage}`
  }
  const [getSearchId, setSearchId] = useState("")
  const responseInfo = useGetAllCategoryQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    language: getLanguage,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  },
    { count: 1 },
    { refetchOnMountOrArgChange: true })
  const categories = responseInfo?.data && responseInfo?.data?.categories;
  const pagination = responseInfo?.data && responseInfo?.data?.pagination;


  const [getEditShow, setEditShow] = useState(false);
  const [getTitle, setTitle] = useState("")
  const [getSubTitle, setSubTitle] = useState("")
  const [getTextColor, setTextColor] = useState("")
  const [getIconUrl, setIconUrl] = useState("")
  const [getUpdateId, updateId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setTitle(data.title)
    setSubTitle(data.subtitle)
    setTextColor(data.text_color)
    setIconUrl(data.icon_url)
    updateId(data.updateId)
  }

  const categoryData = {
    title: getTitle,
    subtitle: getSubTitle,
    text_color: getTextColor,
    icon_url: getIconUrl,
    id: getUpdateId,
    language: getLanguage,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  }


  const [createPost, createresult] = useCreateCategoryMutation()
  const [updatePost, updateresult] = useUpdateCategoryMutation()
  const [deletemedia, deleteresult] = useDeleteMediaMutation()

  useEffect(() => {
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [createresult]);

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
  }, [updateresult]);



  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }

  const [updateImage, imageresult] = useUpdateImageMutation()
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getImageError, setImageError] = useState("");
  const [getUpdateImage, setUpdateImage] = useState("");
  const [getImageBookId, setImageBookId] = useState("");
  const [getImageStatus, setImageStatus] = useState("");

  function updateBookImage() {
    updateImage({
      image_file: getUpdateImage,
      category_id: getImageBookId,
      language: getLanguage,
      userId: userId,
      userToken: userToken,
      userRole: userRole
    })
  }
  useEffect(() => {
    if (imageresult.isSuccess) {
      setImageUpdateModal(false)
    }
  }, [imageresult]);
  function imageUpdate(data) {
    setImageUpdateModal(true)
    setImageBookId(data.category_id)
  }
  function closeImageUpdate() {
    setImageUpdateModal(false)
  }


  function NewFrom() {
    return (
      <New
        show={show}
        handleClose={handleClose}
        setTitle={setTitle}
        setSubTitle={setSubTitle}
        setIconUrl={setIconUrl}
        createresult={createresult}
        createPost={createPost}
        categoryData={categoryData}
      />
    )
  }

  function UpdateForm() {
    return (
      <Update
        getEditShow={getEditShow}
        handleCloseEdit={handleCloseEdit}
        getTitle={getTitle}
        setTitle={setTitle}
        getTextColor={getTextColor}
        setTextColor={setTextColor}
        setSubTitle={setSubTitle}
        getSubTitle={getSubTitle}
        updateresult={updateresult}
        setIconUrl={setIconUrl}
        getIconUrl={getIconUrl}
        updatePost={updatePost}
        categoryData={categoryData}
      />
    )
  }

  function ImageForm() {
    return (
      <ImageUpdate
        getImageUpdateModal={getImageUpdateModal}
        closeImageUpdate={closeImageUpdate}
        getImageError={getImageError}
        setUpdateImage={setUpdateImage}
        imageresult={imageresult}
        updateBookImage={updateBookImage}
      />
    )
  }

  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 80%) !important',
      boxShadow: 'none !important',
      minWidth: '140px',
      maxWidth: '100%'
    }),
    option: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer'
    }),
    menu: styles => ({
      ...styles,
      width: '100%'
    })

  };

  function getFullLanguage() {
    if (getLanguage === 'en') {
      return 'English'
    } else if (getLanguage === 'ar') {
      return 'Arabic'
    } else if (getLanguage === 'desi') {
      return 'Desi'
    } else if (getLanguage === 'urdu') {
      return 'Urdu'
    } else {
      return 'Language'
    }
  }


  function getFullLanguageC(data) {
    if (data === 'en') {
      return 'English'
    } else if (data === 'ar') {
      return 'Arabic'
    } else if (data === 'desi') {
      return 'Desi'
    } else if (data === 'urdu') {
      return 'Urdu'
    } else {
      return 'NA'
    }
  }

  return (
    <div className="tabel_cards category-page">

      {NewFrom()}
      {UpdateForm()}
      {ImageForm()}

      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">
                <div className="updateButton">
                  <div className='dFlex'>
                    <div className="pr" style={{ marginRight: '10px', position: 'relative' }}>
                      <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                      {responseInfo?.isLoading &&
                        <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                      }
                      <SearchIcon className="table_search_icon" />
                    </div>
                    <Select
                      className='category-select-language'
                      styles={customStyles}
                      isDisabled={false}
                      isLoading={responseInfo?.isLoading ? true : false}
                      isClearable={true}
                      defaultValue={getLanguage}
                      onChange={((value) =>
                        history.push(`${window.location.pathname}${`?page=${1}`}${value ? `&language=${value.value}` : ''}`)
                      )}
                      placeholder={getFullLanguage()}
                      options={
                        [
                          { label: 'English', value: 'en', isDisabled: getLanguage === 'en' ? true : false },
                          { label: 'Arabic', value: 'ar', isDisabled: getLanguage === 'ar' ? true : false },
                          { label: 'Desi', value: 'desi', isDisabled: getLanguage === 'desi' ? true : false },
                          { label: 'Urdu', value: 'urdu', isDisabled: getLanguage === 'urdu' ? true : false }
                        ]
                      }
                    />
                  </div>
                  <div className="table_search_card">
                    <div>
                      <Button
                        color="warning"
                        variant="contained"
                        size="small"
                        onClick={() => handleOpen(true)}>
                        Add New
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={responseInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}${getLanguage ? `&language=` + getLanguage : ''}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
                <div className="tabelOverflow">
                  <div className={updateresult.isLoading && 'table-update'}></div>
                  <table className="table">

                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>#</th>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Icon</th>
                        <th>Title</th>
                        <th>Language</th>
                        <th>Date</th>
                        <th className="sticky-column" style={{ textAlign: 'right' }}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {responseInfo?.isLoading &&
                          <TableSkeleton />
                        }
                      </>
                      {categories?.map((item, i) =>
                        <tr key={i}>
                          <td>
                            <a onClick={e => e.preventDefault()}>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={() => handleOpenEdit({
                                  "status": true,
                                  "title": item.title,
                                  "subtitle": item.subtitle,
                                  "text_color": item.text_color,
                                  "icon_url": item.icon_url,
                                  "updateId": item.id
                                })}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </a>
                          </td>
                          <td>{item.id}</td>
                          <td>
                            {item.image ?
                              <>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                  <img src={`https://m.ebtida.com/i/${getLanguage}/${item.id}/${item.image}`} style={{ width: '70px' }} />
                                </div>
                                <Button
                                  disabled={(deleteresult?.isLoading) && (deleteresult?.originalArgs?.id === item.id) ? true : false}
                                  style={{ textTransform: 'capitalize' }}
                                  size='small'
                                  onClick={(() => deletemedia({
                                    id: item.id,
                                    language: getLanguage,
                                    image: item.image,
                                    userId: userId,
                                    userToken: userToken,
                                    userRole: userRole
                                  }))}>{'Delete'}</Button>
                              </>
                              :
                              <Button
                                style={{ textTransform: 'capitalize' }}
                                size='small'
                                onClick={(() => imageUpdate({ category_id: item.id }))}>{'Add'}</Button>
                            }
                          </td>
                          <td>
                            <img src={item.icon_url} style={{ width: '40px' }} />
                          </td>
                          <td style={{ whiteSpace: 'normal', width: '650px', borderLeft: item.text_color && `6px solid ${item.text_color}` }}>
                            <div><strong>{item.title}</strong></div>
                            <small >{item.subtitle}</small>
                          </td>
                          <td>
                            <div>{getFullLanguageC(item.language)}</div>
                          </td>
                          <td>
                            <div>{item.createdAt}</div>
                          </td>
                          <td className="sticky-column">
                            <div className="manage-buttons" style={{ justifyContent: 'right' }}>
                              <Clickable
                                className="mr10"
                                defaultChecked={item.status === 1 ? true : false}
                                onClick={() => updatePost({
                                  id: item.id,
                                  status: item.status === 1 ? 0 : 1,
                                  language: getLanguage,
                                  userId: userId,
                                  userToken: userToken,
                                  userRole: userRole
                                })}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                      }
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
