import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import TableSkeleton from "./TableSkeleton";
import { useGetAllMembersQuery } from '../../services/membership';
// import Pagination from "../Pagination";
import Moment from 'moment';
import { message, Tag, Menu, Dropdown, Switch as Clickable } from "antd";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Select from 'react-select';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
function Membership() {
  const history = useHistory();
  const location = useLocation();
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const getStatus = new URLSearchParams(location.search).get('status') || ''



  const [getSearchId, setSearchId] = useState("")
  const dataInfo = useGetAllMembersQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    searchid: getSearchId,
    status: getStatus
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const membership = dataInfo?.data && dataInfo?.data?.membership;
  const pagination = dataInfo?.data && dataInfo?.data?.pagination;

  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 90%) !important',
      boxShadow: 'none !important',
      minWidth: '140px',
      maxWidth: '100%'
    }),
    option: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer'
    }),
    menu: styles => ({
      ...styles,
      width: '100%'
    })
  };

  function getlabelb(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }


  return (
    <div className="tabel_cards">
      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">
                <div className="updateButton">
                  <div className='dFlex'>
                    <div style={{ position: 'relative', marginRight: '10px' }}>
                      <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                      {dataInfo?.isFetching &&
                        <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                      }
                      <SearchIcon className="table_search_icon" />
                    </div>
                    <div>
                      <Select
                        isClearable={false}
                        styles={customStyles}
                        isLoading={dataInfo?.isFetching ? true : false}
                        defaultValue={getStatus}
                        placeholder={`Status:` + ' ' + getStatus}
                        className='select-options'
                        onChange={((value) =>
                          history.push(`${window.location.pathname}${`?page=${1}`}${value ? `&status=${value.value}` : ''}`)
                        )}
                        options={
                          [
                            { label: getlabelb('All'), value: '', isDisabled: getStatus === '' ? true : false },
                            { label: getlabelb('Active'), value: 'active', isDisabled: getStatus === 'active' ? true : false },
                            { label: getlabelb('Expired'), value: 'expired', isDisabled: getStatus === 'expired' ? true : false }
                          ]
                        }
                      />

                    </div>
                  </div>
                  <div className="table_search_card mrgnRight">
                  </div>
                </div>
                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={dataInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}${getStatus ? `&status=` + getStatus : ''}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
                <div className="tabelOverflow">
                  <table className="table">

                    <thead>
                      <tr>
                        <th className="tac">#</th>
                        <th>User</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>Country/Lang</th>
                        <th>Duration</th>
                        <th>Source</th>
                        <th>Renew</th>
                        <th className="sticky-column tar">Status</th>
                      </tr>
                    </thead>
                    {membership?.length === 0 ?
                      <h5 className="data_not-found">No data available in table</h5>
                      : null}
                    <tbody>
                      <>
                        {dataInfo?.isLoading &&
                          <TableSkeleton />
                        }
                      </>
                      {membership?.map((item, i) =>
                        <tr key={i}>
                          <td>
                            <div>
                              <Dropdown className="Header-waller-connect2" overlay={
                                <Menu className="Header-dropdown-menu">
                                  <MenuItem key={i}>
                                    <span style={{ textTransform: 'capitalize' }}>Update</span>
                                  </MenuItem>

                                </Menu>
                              } trigger={['click']}>
                                <a onClick={e => e.preventDefault()}>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </a>
                              </Dropdown>
                            </div>
                          </td>
                          <td>
                            <div>{item.user?.email}</div>
                          </td>
                          <td>
                            <div>{item.state} {item.notification_type && ` - ${item.notification_type}`}</div>
                          </td>
                          <td>
                            <div>{item.currency} &nbsp; {item.price}</div>
                          </td>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div>{item.country}</div>
                              <div> - </div>
                              <div>{item.language}</div>
                            </div>
                          </td>
                          <td>
                            <div>{Moment(item.start_date).format('Do MMM YY')} - {Moment(item.end_date).format('Do MMM YY')}</div>
                          </td>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div>{item.source} - {item.payment_source}</div>
                              {item.card_country &&
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  <CreditCardIcon style={{ fontSize: '30px', padding: '0px 2px 0px 10px', color: '#212945' }} />
                                  {item.card_country}
                                </span>
                              }
                            </div>
                          </td>


                          <td>
                            <div>
                              {item.source === 'ios' ?
                                <Tag
                                  style={{ margin: 0 }}>
                                  <small style={{ color: '#2c2245' }}>{item.sub_type}</small>
                                </Tag>
                                :
                                <Tag
                                  style={{ margin: 0 }}
                                  color={item.auto_renew === 1 ? "#52a152" : "#ef684f"}>
                                  <small>{item.auto_renew === 1 ? 'Enabled' : 'Disabled'}</small>
                                </Tag>
                              }
                            </div>
                          </td>
                          <td className="sticky-column">
                            <div className="dFlexL jcr">
                              <Tag style={{ margin: 0 }} color={item.active === 1 ? "#52a152" : "#ef684f"}>
                                <small>{item.active === 1 ? 'Active' : 'Expired'}</small>
                              </Tag>
                            </div>
                          </td>
                        </tr>
                      )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Membership;
