import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import { useGetBookByIdQuery } from '../../services/bookItems';
import Skeleton from '@mui/material/Skeleton';
import "react-dropzone-uploader/dist/styles.css";
import AudioPlayer from './AudioPlayer';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, Dropdown } from 'antd';
import AddMdia from './AddMedia';
import { makeStyles } from "@material-ui/core/styles";
import io from 'socket.io-client';
import LinearProgress from '@mui/joy/LinearProgress';
const socket = io(process.env.REACT_APP_API_CONNECT);



const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  bar1Indeterminate: {
    width: "auto",
    animation: "$indeterminate1 60s linear forwards"
  },
  bar2Indeterminate: {
    display: "none"
  },
  "@keyframes indeterminate1": {
    "0%": {
      left: "-35%",
      right: "100%"
    },
    "100%": {
      left: "0%",
      right: "0%"
    }
  }
});



function SummaryList({ viewSummary, deleteSummary, deleteSummaryResult, handleOpenEdit, convertmedia, convertresult, audioUpload }) {
  const { userId, userToken, userRole } = useSelector((state) => state.auth);
  const { bookId, itemId, language, query } = useParams();

  const { data: bookInfo, refetch, isFetching, isLoading } = useGetBookByIdQuery({
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    id: itemId,
    language: language,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const book = bookInfo && bookInfo?.book_item;
  const summaries = bookInfo && bookInfo?.book_item?.summaries;



  const classes = useStyles();
  const [progress, setProgress] = useState({ id: null, loaded: 0, uploaded: 0, resolution: '', type: '' });
  useEffect(() => {
    socket.on(`video${userId}`, (data) => {
      setProgress(data?.results);
    });
    return () => {
      socket.off(`video${userId}`);
    };
  }, []);


  useEffect(() => {
    if (Math.round(Number(progress?.uploaded)) === 100) {
      refetch()
    }
  }, [progress]);



  function loadingValue(loaded, uploaded, id) {
    if (loaded > 0) {
      return true
    } else if (uploaded > 0) {
      return true
    } else {
      return false
    }
  }




  return (
    <div style={{ paddingLeft: '15px' }}>
      {!summaries ?
        <>
          <Skeleton animation="wave" width={'100%'} />
          <Skeleton animation="wave" width={'100%'} />
          <Skeleton animation="wave" width={'100%'} />
          <Skeleton animation="wave" width={'100%'} />
        </>
        :
        <div>
          {summaries?.map((item, i) =>

            <div className="summaries-list row" style={{ position: 'relative' }}>
              <span className='summary-counts'>{i + 1}</span>
              <div className="col-md-12">
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingBottom: '10px' }}>

                  <div style={{ width: '100%', paddingRight: '15px' }} className="HomePage_static_description"
                    dangerouslySetInnerHTML={{
                      __html: item.title
                    }}
                  >
                  </div>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item.id}
                      <Dropdown className="Header-waller-connect2" overlay={(
                        (
                          <Menu className="Header-dropdown-menu summary-dropdown" style={{ borderRadius: '10px' }}>
                            <Menu.Item key="001"
                              onClick={() => viewSummary({ summary: item.summary, title: item.title })}>
                              <Link to="#" style={{ fontSize: '16px' }}>View LiT</Link>
                            </Menu.Item>
                            <Menu.Item key="002"
                              disabled={deleteSummaryResult?.isLoading ? true : false}
                              onClick={() => {
                                if (window.confirm('Are you sure to delete this record?'))
                                  deleteSummary({
                                    language: item.language,
                                    id: item.id,
                                    book_id: book?.book_id,
                                    book_item_id: book?.id,
                                    book_language: book?.language,
                                    media_file: item.audio,
                                    userId: userId,
                                    userToken: userToken,
                                    userRole: userRole,
                                    delete_summary: true
                                  })
                              }}
                            >
                              <Link to="#" style={{ fontSize: '16px' }}>Delete LiT/Media</Link>
                            </Menu.Item>
                            <Menu.Item key="003"
                              onClick={() =>
                                handleOpenEdit({
                                  "summary": item.summary,
                                  "title": item.title,
                                  "image": item.image,
                                  "updateId": item.id
                                })}
                            >
                              <Link to="#" style={{ fontSize: '16px' }}>Update LiT</Link>
                            </Menu.Item>
                            {item.audio ?
                              <Menu.Item key="004"
                                disabled={deleteSummaryResult?.isLoading ? true : false}
                                onClick={() => {
                                  if (window.confirm('Are you sure to delete this record?'))
                                    deleteSummary({
                                      language: item.language,
                                      id: item.id,
                                      book_id: book?.book_id,
                                      book_item_id: book?.id,
                                      book_language: book?.language,
                                      media_file: item.audio,
                                      userId: userId,
                                      userToken: userToken,
                                      userRole: userRole,
                                      delete_media: true
                                    })
                                }}
                              >
                                <Link to="#" style={{ fontSize: '16px' }}>Delete Media</Link>
                              </Menu.Item> :
                              <Menu.Item key="004"
                                onClick={() =>
                                  audioUpload({
                                    id: item.id,
                                    status: true,
                                    book_id: book?.book_id,
                                    book_item_id: book?.id,
                                    book_language: book?.language,
                                    media_type: book?.media_type
                                  })}
                              >
                                <Link to="#" style={{ fontSize: '16px' }}>Add Media</Link>
                              </Menu.Item>
                            }

                          </Menu>
                        )
                      )} trigger={['click']}>
                        <IconButton aria-haspopup="true">
                          <MoreVertIcon style={{ color: '#1e2f438f' }} />
                        </IconButton>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row a-i">
                  <div className="col-md-5 book-details-side-summaries">
                    <div style={{ paddingRight: '15px' }}>
                      {item.image &&
                        <img src={item.image} style={{ width: '100%' }} />
                      }
                    </div>
                  </div>
                  <div className="col-md-7 book-details-side-summaries">
                    {item.audio &&
                      <div className="row">
                        <div className="col-md-12">
                          <div className="cms-player-width">
                            <AudioPlayer
                              book_id={book?.book_id}
                              language={language}
                              book_item_id={book?.id}
                              id={item.id}
                              media={item.audio}
                              directory={book?.media_type === 'audio' ? 'audio' : 'video'}
                              dir={book?.media_type === 'audio' ? 'a' : 'v'}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    <div>
                      <AddMdia
                        audio={item.audio}
                        id={item.id}
                        book_id={book?.book_id}
                        book_item_id={book?.id}
                        book_language={book?.language}
                        media_type={book?.media_type}
                      />
                    </div>
                  </div>


                  <div className="col-md-12">
                    {progress?.id === item.id &&
                      <>
                        {loadingValue(progress?.loaded, progress?.uploaded, item.id) &&
                          <>
                            <div>
                              {Math.round(Number(progress?.uploaded)) === 100 ?
                                <span style={{ color: 'green' }}>Completed!</span> :
                                <span style={{ color: '#000' }}>
                                  {progress?.type} {progress?.resolution}  {`${Math.round(Number(progress?.loaded)) || 0}%`}
                                </span>
                              }
                            </div>
                            <LinearProgress
                              style={{ color: Math.round(Number(progress?.uploaded)) === 100 ? 'green' : '#2c2245' }}
                              className="progress-media"
                              determinate
                              variant="outlined"
                              color="neutral"
                              size="sm"
                              thickness={7}
                              value={progress?.loaded || 0}
                              sx={{
                                '--LinearProgress-radius': '4px',
                                '--LinearProgress-thickness': '7px',
                              }}
                              classes={{
                                bar1Indeterminate: classes.bar1Indeterminate,
                                bar2Indeterminate: classes.bar2Indeterminate
                              }}
                            >
                            </LinearProgress>
                          </>
                        }
                      </>
                    }

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
}

export default SummaryList;
