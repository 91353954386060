import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const staticsApi = createApi({
  reducerPath: 'staticsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Static'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getAllStatics: builder.query({
      query: (data) => {
        return {
          url: `statics`,
          method: 'GET',
          headers: {
            'X-User-Token': localStorage.getItem('userToken'),
            'X-User-Id': localStorage.getItem('userId'),
            'X-User-Role': localStorage.getItem('userRole'),
            'X-Language': data.languageCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Static'],
    }),


    updateStatic: builder.mutation({
      query: (data) => ({
        url: `statics`,
        method: 'PUT',
        body: data,
        headers: {
          'X-User-Token': localStorage.getItem('userToken'),
          'X-User-Id': localStorage.getItem('userId'),
          'X-User-Role': localStorage.getItem('userRole'),
          'X-Language': localStorage.getItem('languageCode'),
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Static'],
    }),


  }),

})

export const { useGetAllStaticsQuery, useUpdateStaticMutation } = staticsApi