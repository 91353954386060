import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import Loader from '../../Helper/Loader';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
function New(props) {

  return (
    <>
      <Modal className="Category-new-modal" show={props.show} onHide={props.handleClose} animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>New Category</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>

        <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={props.handleClose}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>New</h5>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Category Name</label>
                <input type="text" className="form-control" id="name"
                  onChange={(e) => props.setTitle(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Icon URL</label>
                <input type="text" className="form-control" id="icon"
                  onChange={(e) => props.setIconUrl(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Category Slug</label>
                <textarea className="form-control" id="name"
                  onChange={(e) => props.setSubTitle(e.target.value)} > </textarea>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Button
                  style={{ background: '#2c2245cf', color: '#f7f7f7' }}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={() => !props.createresult.isLoading && props.createPost(props.categoryData)}>
                  {props.createresult.isLoading ? <Loader /> : 'Create'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default New;
