import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Modal, DropdownButton } from 'react-bootstrap'
import TableSkeleton from "./TableSkeleton";
import SearchIcon from "@material-ui/icons/Search";
import { Tag } from "antd";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetBookItemsQuery,
  useGetSearchUserQuery,
  useUpdateTaskMutation,
  useCreateTaskMutation
} from '../../services/listing';
// import Pagination from "../Pagination";
import './index.scss';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import ReviewsIcon from '@mui/icons-material/Reviews';
import moment from 'moment';
import Loader from '../../Helper/Loader';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Select, { components } from 'react-select';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import StarIcon from '@mui/icons-material/Star';
import GradingIcon from '@mui/icons-material/Grading';
function BookTracking() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch()
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const getState = new URLSearchParams(location.search).get('state') || ''
  const getLanguage = new URLSearchParams(location.search).get('language') || ''
  const getCategory = new URLSearchParams(location.search).get('category') || ''
  const getAssignee = new URLSearchParams(location.search).get('assignee') || ''
  const getStatus = new URLSearchParams(location.search).get('status') || ''
  const getProductStatus = new URLSearchParams(location.search).get('product_status') || ''

  const [getSearchId, setSearchId] = useState("")

  // get book item list
  const { data: bookItems, isFetching, isLoading, isSuccess, refetch } = useGetBookItemsQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    searchid: getSearchId,
    state: getState,
    language: getLanguage,
    assignee: getAssignee,
    category: getCategory,
    product_status: getProductStatus,
    status: getStatus
  }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const pagination = bookItems?.pagination;



  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     refetch()
  //   }, 300000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);




  // user search query
  const [getTasksData, setTasksData] = useState([])
  const [getTaskModal, setTaskModal] = useState(false)

  const [getSelectedStates, setSelectedStates] = useState(null);
  const [getSelectedAssignee, setSelectedAssignee] = useState('');
  const [getUserSearchValue, setUserSearchValue] = useState(null);


  const [getAssigneeName, setAssigneeName] = useState('');
  const customFilter = (selectedOption) => {
    setAssigneeName(selectedOption)
  };

  // get assignee data
  const userSearchData = useGetSearchUserQuery({
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    query: getAssigneeName,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const userData = userSearchData?.data && userSearchData?.data?.user;




  // display assignee search and update assignee loader
  function getUserSelectLoading(id) {
    if (userSearchData?.isFetching && userSearchData?.originalArgs?.searchId === id) {
      return true
    } else if ((taskUpdateResult?.isLoading && taskUpdateResult?.originalArgs?.id === id) && (taskUpdateResult?.originalArgs?.userUpdateId === id)) {
      return true
    }
  }

  // serach for asignee
  function renderList(taskId) {
    return (
      userData?.map(data => ({
        label: data.email ? data.email : data.username,
        value: data.email ? data.email : data.username,
        id: taskId,
        assigneeId: data.id
      }))
    )
  }


  // open task assignee modal
  function openTaskModal(data) {
    setTaskModal(data.status)
    setTasksData(data)
  }
  function closeTaskModal(data) {
    setTaskModal(false)
  }


  const [getViewTaskModal, setViewTaskModal] = useState()

  const [taskUpdate, taskUpdateResult] = useUpdateTaskMutation()
  const [taskCreate, taskCreateResult] = useCreateTaskMutation()

  // tasks update or create
  useEffect(() => {
    if (getSelectedAssignee?.value) {
      taskUpdate({
        id: getSelectedAssignee?.id,
        assignee: getSelectedAssignee?.value,
        assignee_id: getSelectedAssignee?.assigneeId,
        userId: userId,
        userToken: userToken,
        userRole: userRole,
        userUpdateId: getSelectedAssignee?.id
      })
    }
    if (getSelectedStates?.value) {
      taskCreate({
        id: getTasksData?.itemId,
        state: getSelectedStates?.value,
        userId: userId,
        userToken: userToken,
        userRole: userRole
      })
    }

    if (taskUpdateResult?.isSuccess) {
      setViewTaskModal(false)
    }

    if (taskUpdateResult) {
      setSelectedAssignee('')
    }
    if (taskCreateResult) {
      setSelectedStates('')
    }
  }, [getSelectedAssignee, getSelectedStates]);


  function displayStatus(data) {
    let displayData
    if (data === 'in_progress') {
      displayData = {
        color: '#ffd65d',
        name: 'In-Progress'
      }
    }
    else if (!data) {
      displayData = {
        color: '#ffd65d',
        name: 'Pending'
      }
    }
    else if (data === 'completed') {
      displayData = {
        color: '#72c585',
        name: 'Completed'
      }
    }
    else if (data === 'on_hold') {
      displayData = {
        color: '#eb8188',
        name: 'On Hold'
      }
    }
    return displayData
  }

  function getNumberOfDays(startDay, endDay) {
    const startDate = moment(startDay, 'DD-MM-YYYY');
    const endDate = moment(endDay, 'DD-MM-YYYY');
    const days = endDate.diff(startDate, 'days');
    if (endDate > startDate) {
      return days + " Day(s)"
    }
    else if (startDay && startDay === endDay && endDay) {
      return 1 + ' ' + "Day(s)"
    }
    else if (startDate && !endDate) {
      return days + " Day(s)"
    }
    else if (!startDate && !endDate) {
      return ''
    } else {
      return ''
    }
  }

  function diaplyStateAndStatus(state, status, length, startDate, endDate, reviewer, reviewed) {
    let stateColor;
    let textColor
    if (status === 'in_progress') {
      stateColor = '#ffd65d'
      textColor = '#000'
    }
    else if (status === 'completed') {
      stateColor = '#72c585'
    }
    else if (status === 'on_hold') {
      stateColor = '#eb8188'
    }
    else if (!status) {
      stateColor = '#b7b7b7'
    }
    return (
      <div style={{ position: 'relative' }}>
        <Tag className="booksTag" color={status ? stateColor : '#55acee'} style={{ color: textColor }}>
          {length > 0 ? state : 'not assigned'}
        </Tag>
        <StarIcon disabled
          style={{ color: reviewed === 1 ? '#72c585' : '#eb8188', position: 'absolute', zIndex: 111, right: '5px', top: '-5px', fontSize: '12px' }}
        />
      </div>
    )
  }

  function getLanguageName(language) {
    let name;
    if (language === 'en') {
      name = 'English'
    } else if (language === 'ar') {
      name = 'Arabic'
    }
    else if (language === 'desi') {
      name = 'Desi'
    }
    else if (language === 'urdu') {
      name = 'Urdu'
    } else {
      name = 'NA'
    }
    return (
      <div><small><strong>Language:&nbsp; </strong>{name}</small></div>
    )
  }


  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 90%) !important',
      boxShadow: 'none !important',
      minWidth: '140px',
      maxWidth: '100%'
    }),
    option: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer'
    }),
    menu: styles => ({
      ...styles,
      width: '100%'
    })
  };

  function getlabel(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }

  function getlabelb(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }

  function getlabelc(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }

  function getlabelps(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }

  function getStatusLabel(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }

  function getFullLanguage() {
    if (getState === 'tranlating') {
      return 'Translating'
    } else if (getState === 'proof_reading') {
      return 'Proof Reading'
    } else if (getState === 'editing') {
      return 'Editing'
    } else if (getState === 'voice_over') {
      return 'Voice Over'
    } else if (getState === 'post_production') {
      return 'Post Production'
    } else if (getState === 'finalization') {
      return 'Finalization'
    } else {
      return 'Select'
    }
  }

  function getFullLanguage(data) {
    if (data === 'en') {
      return 'english'
    } else if (data === 'ar') {
      return 'arabic'
    } else if (data === 'desi') {
      return 'desi'
    } else if (data === 'urdu') {
      return 'urdu'
    } else {
      return 'missing'
    }
  }


  const customStylesAssignee = {
    control: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 90%) !important',
      boxShadow: 'none !important',
      minWidth: '240px',
      maxWidth: '100%'
    }),
    option: styles => ({
      ...styles,
      minWidth: '240px',
      maxWidth: '100%'
    }),
    menu: styles => ({
      ...styles,
      minWidth: '240px',
      maxWidth: '100%'
    })
  };


  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <div style={{ with: '100%', cursor: 'pointer', textAlign: 'left' }}
          onClick={(() => history.push(`${window.location.pathname}${`?page=${1}`}${getState ? `&state=${getState}` : ''}${getLanguage ? `&language=${getLanguage}` : ''}${getStatus ? `&status=${getStatus}` : ''}${`&assignee=${``}`}`)
          )}>All</div>
      </components.NoOptionsMessage>
    );
  };




  const [getTaskData, setTaskData] = useState([])
  function taskViewModal(data) {
    setViewTaskModal(data)
    setTaskData(data)
  }

  function closeTaskViewModal() {
    setViewTaskModal(false)
  }


  return (
    <div className="tabel_cards books-page">

      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">
                <div className="updateButton">
                  <div className='dFlex'>
            
                    <div style={{ marginRight: '10px' }}>
                      <Select
                        styles={customStyles}
                        isDisabled={false}
                        isClearable={false}
                        defaultValue={getProductStatus}
                        onChange={((value) =>
                          // setSelectedLanguage({ value: value ? value.value : '', pageToOne: 1 })
                          history.push(`${window.location.pathname}${`?page=${1}`}${getState ? `&state=${getState}` : ''}${getAssignee ? `&assignee=${getAssignee}` : ''}${getStatus ? `&status=${getStatus}` : ''}${getLanguage ? `&language=${getLanguage}` : ''}${getCategory ? `&category=${getCategory}` : ''}${value ? `&product_status=${value.value}` : ''}`)
                        )}
                        placeholder={`Status:` + ' ' + getProductStatus}
                        className='select-options'
                        options={
                          [
                            { label: getlabelps('All'), value: '', isDisabled: getProductStatus === '' ? true : false },
                            { label: getlabelps('Published'), value: 1, isDisabled: getProductStatus === 1 ? true : false },
                            { label: getlabelps('Unpublished'), value: 0, isDisabled: getProductStatus === 0 ? true : false },
                          ]
                        }
                      />
                    </div>



                    <div style={{ marginRight: '10px' }}>
                      <Select
                        styles={customStyles}
                        isDisabled={false}
                        isClearable={false}
                        defaultValue={getCategory}
                        onChange={((value) =>
                          // setSelectedLanguage({ value: value ? value.value : '', pageToOne: 1 })
                          history.push(`${window.location.pathname}${`?page=${1}`}${getState ? `&state=${getState}` : ''}${getAssignee ? `&assignee=${getAssignee}` : ''}${getStatus ? `&status=${getStatus}` : ''}${getLanguage ? `&language=${getLanguage}` : ''}${getProductStatus ? `&product_status=${getProductStatus}` : ''}${value ? `&category=${value.value}` : ''}`)
                        )}
                        placeholder={`Category:` + ' ' + getCategory}
                        className='select-options'
                        options={
                          [
                            { label: getlabelc('All'), value: '', isDisabled: getCategory === '' ? true : false },
                            { label: getlabelc('Books'), value: 'books', isDisabled: getCategory === 'books' ? true : false },
                            { label: getlabelc('Documentary'), value: 'documentary', isDisabled: getCategory === 'documentary' ? true : false },
                            { label: getlabelc('Courses'), value: 'courses', isDisabled: getCategory === 'courses' ? true : false },
                            { label: getlabelc('News'), value: 'news', isDisabled: getCategory === 'news' ? true : false },
                            { label: getlabelc('Ads'), value: 'ads', isDisabled: getCategory === 'ads' ? true : false }
                          ]
                        }
                      />
                    </div>

                    <div style={{ marginRight: '10px' }}>
                      <Select
                        styles={customStyles}
                        isDisabled={false}
                        isClearable={false}
                        defaultValue={getLanguage}
                        onChange={((value) =>
                          // setSelectedLanguage({ value: value ? value.value : '', pageToOne: 1 })
                          history.push(`${window.location.pathname}${`?page=${1}`}${getState ? `&state=${getState}` : ''}${getAssignee ? `&assignee=${getAssignee}` : ''}${getStatus ? `&status=${getStatus}` : ''}${getCategory ? `&category=${getCategory}` : ''}${getProductStatus ? `&product_status=${getProductStatus}` : ''}${value ? `&language=${value.value}` : ''}`)
                        )}
                        placeholder={`Language:` + ' ' + getLanguage}
                        className='select-options'
                        options={
                          [
                            { label: getlabelb('All'), value: '', isDisabled: getLanguage === '' ? true : false },
                            { label: getlabelb('English'), value: 'en', isDisabled: getLanguage === 'en' ? true : false },
                            { label: getlabelb('Arabic'), value: 'ar', isDisabled: getLanguage === 'ar' ? true : false },
                            { label: getlabelb('Desi'), value: 'desi', isDisabled: getLanguage === 'desi' ? true : false },
                            { label: getlabelb('Urdu'), value: 'urdu', isDisabled: getLanguage === 'urdu' ? true : false }
                          ]
                        }
                      />
                    </div>

                    <div style={{ marginRight: '10px' }}>
                      <Select
                        isMulti={true}
                        styles={customStyles}
                        isDisabled={false}
                        isClearable={false}
                        defaultValue={getState}
                        onChange={((value) =>
                          history.push(`${window.location.pathname}${`?page=${1}`}${getLanguage ? `&language=${getLanguage}` : ''}${getAssignee ? `&assignee=${getAssignee}` : ''}${getStatus ? `&status=${getStatus}` : ''}${getCategory ? `&category=${getCategory}` : ''}${getProductStatus ? `&product_status=${getProductStatus}` : ''}${value ? `&state=${value?.map(data => data.value)}` : ''}`)
                        )}
                        placeholder={`Pipeline:` + ' ' + getState}
                        className='select-options'
                        options={
                          [
                            { label: getlabel('All'), value: '', isDisabled: getState === '' ? true : false },
                            { label: getlabel('Translating'), value: 'tranlating', isDisabled: getState === 'tranlating' ? true : false },
                            { label: getlabel('Proof Reading'), value: 'proof_reading', isDisabled: getState === 'proof_reading' ? true : false },
                            { label: getlabel('Editing'), value: 'editing', isDisabled: getState === 'editing' ? true : false },
                            { label: getlabel('Voice Over'), value: 'voice_over', isDisabled: getState === 'voice_over' ? true : false },
                            { label: getlabel('Post Production'), value: 'post_production', isDisabled: getState === 'post_production' ? true : false },
                            { label: getlabel('Finalization'), value: 'finalization', isDisabled: getState === 'finalization' ? true : false }
                          ]
                        }
                      />
                    </div>


                    <div style={{ marginRight: '10px' }}>
                      <Select
                        styles={customStyles}
                        isDisabled={false}
                        isClearable={false}
                        defaultValue={getStatus}
                        onChange={((value) =>
                          history.push(`${window.location.pathname}${`?page=${1}`}${getState ? `&state=${getState}` : ''}${getAssignee ? `&assignee=${getAssignee}` : ''}${getLanguage ? `&language=${getLanguage}` : ''}${getCategory ? `&category=${getCategory}` : ''}${getProductStatus ? `&product_status=${getProductStatus}` : ''}${value ? `&status=${value.value}` : ''}`)
                        )}
                        placeholder={`State:` + ' ' + getStatus}
                        className='select-options'
                        options={
                          [
                            { label: getStatusLabel('All'), value: '', isDisabled: getStatus === '' ? true : false },
                            { label: getStatusLabel('In Progress'), value: 'in_progress', isDisabled: getStatus === 'in_progress' ? true : false },
                            { label: getStatusLabel('On Hold'), value: 'on_hold', isDisabled: getStatus === 'on_hold' ? true : false },
                            { label: getStatusLabel('Completed'), value: 'completed', isDisabled: getStatus === 'completed' ? true : false }
                          ]
                        }
                      />
                    </div>
                    <div>
                      <Select
                        isMulti={true}
                        components={{ NoOptionsMessage }}
                        styles={customStylesAssignee}
                        onInputChange={customFilter}
                        isClearable={false}
                        defaultValue={getAssignee}
                        placeholder={`Assignee:` + ' ' + getAssignee}
                        className='select-options'
                        onChange={((value) =>
                          history.push(`${window.location.pathname}${`?page=${1}`}${getLanguage ? `&language=${getLanguage}` : ''}${getState ? `&state=${getState}` : ''}${getStatus ? `&status=${getStatus}` : ''}${getCategory ? `&category=${getCategory}` : ''}${value ? `&assignee=${value?.map(data => data.value)}` : ''}`)
                        )}
                        options={renderList()}
                      />
                    </div>


                  </div>
                 
                </div>

                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}${getState ? `&state=` + getState : ''}${getStatus ? `&status=${getStatus}` : ''}${getLanguage ? `&language=` + getLanguage : ''}${getAssignee ? `&assignee=${getAssignee}` : ''}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>

                <div className="tabelOverflow">

          
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}></th>
                        <th>#</th>
                        <th>Title</th>
                        <th>Language</th>
                        <th>Category</th>
                        <th className="sticky-column" style={{ textAlign: 'right' }}>
                          Pipeline
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {isLoading &&
                          <TableSkeleton />
                        }
                      </>
                      {bookItems?.book_items?.map((item, i) => {

                        const tranlatingF = item.tasks?.filter(trans => trans.state === 'tranlating');
                        const proof_readingF = item.tasks?.filter(proof => proof.state === 'proof_reading');
                        const editingF = item.tasks?.filter(proof => proof.state === 'editing');
                        const voice_overF = item.tasks?.filter(proof => proof.state === 'voice_over');
                        const post_productionF = item.tasks?.filter(proof => proof.state === 'post_production');
                        const finalizationF = item.tasks?.filter(proof => proof.state === 'finalization');
                        let proof_readingD;
                        let editingD;
                        let voice_overD;
                        let post_productionD;
                        let finalizationD;
                        if (tranlatingF?.length > 0 && proof_readingF?.length === 0) {
                          proof_readingD = false
                        } else if (tranlatingF?.length > 0 && proof_readingF?.length > 0) {
                          proof_readingD = true
                        } else if (tranlatingF?.length === 0 && proof_readingF?.length === 0) {
                          proof_readingD = true
                        }

                        if (proof_readingF?.length > 0 && editingF?.length === 0) {
                          editingD = false
                        } else if (proof_readingF?.length > 0 && editingF?.length > 0) {
                          editingD = true
                        } else if (proof_readingF?.length === 0 && editingF?.length === 0) {
                          editingD = true
                        }

                        if (editingF?.length > 0 && voice_overF?.length === 0) {
                          voice_overD = false
                        } else if (editingF?.length > 0 && voice_overF?.length > 0) {
                          voice_overD = true
                        } else if (editingF?.length === 0 && voice_overF?.length === 0) {
                          voice_overD = true
                        }

                        if (voice_overF?.length > 0 && post_productionF?.length === 0) {
                          post_productionD = false
                        } else if (voice_overF?.length > 0 && post_productionF?.length > 0) {
                          post_productionD = true
                        } else if (voice_overF?.length === 0 && post_productionF?.length === 0) {
                          post_productionD = true
                        }

                        if (post_productionF?.length > 0 && finalizationF?.length === 0) {
                          finalizationD = false
                        } else if (post_productionF?.length > 0 && finalizationF?.length > 0) {
                          finalizationD = true
                        } else if (post_productionF?.length === 0 && finalizationF?.length === 0) {
                          finalizationD = true
                        }

                        return (
                          <>
                            <tr key={i}>
                              <td>
                                <div>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(() => openTaskModal({
                                      status: true,
                                      itemId: item.id,
                                      bookId: item.book_id,
                                      language: item.language,
                                      title: item.title,
                                      tags: item.tags,
                                      isbn: item.isbn,
                                      authors: item.authors,
                                      image: item.image,
                                      taskAssignee: item.task?.assignee,
                                      taskState: item.task?.state,
                                      taskId: item.task?.id,
                                    }))}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </div>
                              </td>
                              <td>{item.id}</td>
                              <td style={{ position: 'relative' }}>
                                <Link to={`/book-details/${item.book_id}/${item.id}/${item.language}/${item.title.replace(/\s+/g, '-').toLowerCase()}`}>
                                  <div style={{ whiteSpace: 'normal', width: '320px' }}>{item.title}</div>
                                </Link>

                              </td>
                              <td>
                                {getFullLanguage(item.language)}
                              </td>
                              <td>
                                <div style={{ whiteSpace: 'normal' }}>
                                  {item.category}
                                </div>
                              </td>

                              <td className="sticky-column" style={{ textAlign: 'right', width: '1px' }}>
                                <div style={{ whiteSpace: 'normal', display: 'flex', justifyContent: 'right' }}>
                                  {item.tasks?.length > 0 &&
                                    <>
                                      {item.tasks?.map((taskItem, i) =>
                                        <div>
                                          {diaplyStateAndStatus(taskItem.state, taskItem.status, item.tasks?.length, taskItem.start_date, taskItem.end_date, taskItem.reviewer, taskItem.reviewed)}
                                        </div>
                                      )}
                                    </>
                                  }
                                  {item.tasks?.length === 0 &&
                                    <Tag className="booksTag" color={'#b7b7b7'}>
                                      Not Assigned
                                    </Tag>
                                  }
                                </div>
                              </td>
                            </tr>


                            <Modal className="task-Modal" show={getTasksData?.itemId === item.id ? getTaskModal : false} onHide={closeTaskModal} animation={false}>
                              {/* <Modal.Header closeButton style={{ display: 'flex', alignItems: 'center' }}>
                                <Modal.Title>{userEmail}</Modal.Title>
                              </Modal.Header> */}
                              <div>
                                <IconButton
                                  style={{ position: 'absolute', right: '5px', zIndex: 111, top: '5px' }}
                                  aria-label="delete"
                                  onClick={closeTaskModal}>
                                  <CloseIcon />
                                </IconButton>
                              </div>
                              <Modal.Body>
                                <br />
                                <div className="row">
                                  <div className="col-md-8">
                                    <div style={{ display: 'flex', paddingBottom: '20px' }}>
                                      <div style={{ paddingRight: '20px' }}>
                                        <img src={getTasksData?.image ? `https://dj70nht8amdum.cloudfront.net/${getTasksData?.bookId}/${getTasksData?.language}/${getTasksData?.itemId}/${getTasksData?.image}` : '/No-Image-Available-1.png'} width="150px" />
                                      </div>
                                      <div>
                                        <div>
                                          <Link to={`/book-details/${item.book_id}/${item.id}/${item.language}/${item.title.replace(/\s+/g, '-').toLowerCase()}`}>
                                            <h6><strong>{getTasksData?.title} #{getTasksData?.image}</strong></h6>
                                          </Link>
                                          <p><small><strong>ISBN:&nbsp; </strong>{getTasksData?.isbn}</small></p>
                                        </div>
                                        <div>
                                          <div><small><strong>Authors:&nbsp; </strong>{getTasksData?.authors}</small></div>
                                        </div>
                                        <div>
                                          {getLanguageName(item.language)}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div style={{ whiteSpace: 'normal' }}>
                                        {getTasksData?.tags?.split(',').map((concept, i) =>
                                          <>
                                            <Tag className="booksTag" color="#55acee">
                                              {concept}
                                            </Tag>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group" style={{ borderBottom: '1px solid #eee', paddingBottom: '10px' }}>
                                      <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '7px' }}>
                                        <span>Pipeline</span>
                                      </div>
                                      <Select
                                        className='select-options'
                                        styles={customStylesAssignee}
                                        isDisabled={false}
                                        isLoading={(taskCreateResult?.isLoading) ? true : false}
                                        isClearable={false}
                                        defaultValue={getSelectedStates}
                                        onChange={setSelectedStates}
                                        options={
                                          [
                                            { label: 'Select', value: '', isDisabled: true },
                                            { label: 'Tranlating', value: 'tranlating', isDisabled: tranlatingF?.length > 0 ? true : false },
                                            { label: 'Proof Reading', value: 'proof_reading', isDisabled: proof_readingD },
                                            { label: 'Editing', value: 'editing', isDisabled: editingD },
                                            { label: 'Voice Over', value: 'voice_over', isDisabled: voice_overD },
                                            { label: 'Post Production', value: 'post_production', isDisabled: post_productionD },
                                            { label: 'Finalization', value: 'finalization', isDisabled: finalizationD }

                                          ]
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="tabelOverflow" style={{ fontSize: '13px' }}>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th style={{ textAlign: 'center' }}>Reset</th>
                                            <th>#</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>Start Date</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>End Date</th>
                                            <th>Days</th>
                                            <th>Assignee</th>
                                            <th>Review</th>
                                            <th className="sticky-column" style={{ textAlign: 'right' }}>
                                              Status
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item.tasks?.map((items, i) =>
                                            <tr key={i}>
                                              <td style={{ textAlign: 'center' }}>
                                                {(taskUpdateResult?.isLoading && taskUpdateResult?.originalArgs?.id === items.id) && taskUpdateResult?.originalArgs?.reset_task ? <Loader /> :
                                                  <RestartAltIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(() =>
                                                      taskUpdate({
                                                        id: items.id,
                                                        reset_task: 'reset_task',
                                                        userId: userId,
                                                        userToken: userToken,
                                                        userRole: userRole
                                                      })
                                                    )}
                                                  />
                                                }
                                              </td>
                                              <td>
                                                {items.id}
                                              </td>
                                              <td>
                                                {items.start_date}
                                              </td>
                                              <td>
                                                {items.end_date}
                                              </td>
                                              <td>
                                                {getNumberOfDays(items.start_date, items.end_date ? items.end_date : moment().format("DD/MM/YYYY"))}
                                              </td>
                                              <td style={{ width: '250px' }}>
                                                {items.assignee ? items.assignee :
                                                  <>
                                                    <Select
                                                      // noOptionsMessage={() => userSearchData?.isFetching && userSearchData?.originalArgs?.searchId === items.id ? <Loader /> : 'nothing found'}
                                                      isDisabled={false}
                                                      isLoading={getUserSelectLoading(items.id)}
                                                      isClearable={false}
                                                      defaultValue={getSelectedAssignee?.value}
                                                      placeholder={`Assignee:` + ' ' + getSelectedAssignee?.value && items.id === getSelectedAssignee?.id && getSelectedAssignee?.value}
                                                      onChange={((value) => setSelectedAssignee(value))}
                                                      className='select-options'
                                                      styles={customStylesAssignee}
                                                      options={renderList(items.id)}
                                                      onInputChange={customFilter}
                                                    />

                                                  </>
                                                }
                                              </td>

                                              <td>
                                                <div>
                                                  {(taskUpdateResult?.isLoading) && (taskUpdateResult?.originalArgs?.id === items.id) && (taskUpdateResult?.originalArgs?.reviewUpdate === 'review') ? <Loader /> :
                                                    <IconButton
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={(() => taskViewModal(items.id))}>
                                                      <GradingIcon />
                                                    </IconButton>
                                                  }

                                                </div>
                                              </td>

                                              <td className="sticky-column" style={{ textAlign: 'right' }}>
                                                <div className="manage-buttons" style={{ justifyContent: 'right' }}>
                                                  <Tag className="booksTag" color={displayStatus(items.status).color} title={displayStatus(items.status).title}>
                                                    {items.state}
                                                  </Tag>


                                                </div>
                                              </td>



                                              <Modal className="view-Task-Modal" show={getViewTaskModal === items.id ? true : false} onHide={closeTaskViewModal} animation={false}>
                                                <div>
                                                  <IconButton
                                                    style={{ position: 'absolute', right: '5px', zIndex: 111, top: '5px' }}
                                                    aria-label="delete"
                                                    onClick={closeTaskViewModal}>
                                                    <CloseIcon />
                                                  </IconButton>
                                                </div>
                                                <Modal.Body>
                                                  <br /><br />
                                                  <Tag><h4>{items.assignee}</h4></Tag>
                                                  <div>
                                                    <div style={{ padding: '0px 0px 20px 0px', display: 'flex', justifyContent: 'flex-end' }}>
                                                      <Button
                                                        disabled={taskUpdateResult?.isLoading ? true : false}
                                                        color={items.reviewed === 1 ? "success" : "warning"}
                                                        variant="contained"
                                                        size="small"
                                                        onClick={(() => taskUpdate({
                                                          id: items.id,
                                                          reviewed: items.reviewed === 1 ? 0 : 1,
                                                          reviewer: userEmail,
                                                          userId: userId,
                                                          userToken: userToken,
                                                          userRole: userRole,
                                                          reviewUpdate: 'review'
                                                        }))}
                                                      >
                                                        <small>{taskUpdateResult?.isLoading ? 'Loading...' : items.reviewed === 1 ? 'Reviewed' : 'Mark as a reviewed'}</small>
                                                      </Button>
                                                    </div>
                                                    <>
                                                      {items?.task_comments?.map((task_comment, i) =>
                                                        <div
                                                          key={i}
                                                          style={{ padding: '10px', background: '#f3f3f3', border: '1px solid #eee', borderRadius: '4px', marginBottom: '10px' }}>
                                                          <div>{task_comment.comment}</div>
                                                          <div>
                                                            <a href={`https://m.ebtida.com/d/${items?.id}/${JSON.parse(task_comment.file)}`} target='_blank'>
                                                              {JSON.parse(task_comment.file)}
                                                            </a>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </>
                                                  </div>
                                                </Modal.Body>
                                              </Modal>



                                            </tr>
                                          )
                                          }
                                        </tbody>
                                      </table>
                                      {item.tasks?.length === 0 && <h3 style={{ textAlign: 'center', fontSize: '1rem', color: '#1b21384f' }}>Not Assigned!</h3>}
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookTracking;
