import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import Select from 'react-select';
import { useGetAllCategoryQuery } from '../../services/categories';
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
function UpdateBookItem({ data, show, close, updateBook, isLoading, itemId, language, langDirection, bookTitle }) {
  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  // list all categories
  const categoriesData = useGetAllCategoryQuery({
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    page: 1,
    size: 100,
    language: language ? language : null,
    searchid: '',
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const categories = categoriesData?.data && categoriesData?.data?.categories;

  const existingArray = data?.tags?.split(',').map((concept, i) => concept)

  function renderList() {
    return (categories?.map(dataa => ({ label: dataa.title, value: dataa.title })))
  }

  let arrajasa = [];
  if (categories) {
    categories?.map(data =>
      arrajasa.push({ label: data.title, value: data.title })
    )
  }

  const withTitle = [{ label: bookTitle, value: bookTitle }];
  const listOfTags = [...withTitle, ...arrajasa];

  function renderListab() {
    return (existingArray?.map(dataaa => ({ label: dataaa, value: dataaa })))
  }

  const [selectedOption, setSelectedOption] = useState(null);
  const [getTitle, setTitle] = useState("")
  const [getSubTitle, setSubTitle] = useState("")
  const [getIsbn, setIsbn] = useState("")
  const [getAuthor, setAuthor] = useState("")
  const [getDetails, setDetails] = useState("")
  const [getPostType, setPostType] = useState("")
  const [getCategory, setCategory] = useState("")

  const updateBookData = {
    title: getTitle ? getTitle : data?.title,
    subtitle: getSubTitle ? getSubTitle : data?.subtitle,
    isbn: getIsbn ? getIsbn : data?.isbn,
    authors: getAuthor ? getAuthor : data?.authors,
    details: getDetails ? getDetails : data?.details,
    media_type: getPostType ? getPostType : data?.media_type,
    category: getCategory ? getCategory : data?.category,
    language: language,
    id: itemId,
    tags: selectedOption ? selectedOption?.map(item => item.value).join(', ') : data?.tags,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  }


  function buttonDisabled() {
    if (selectedOption?.length === 0) {
      return true
    } else if (isLoading) {
      return true
    } else {
      false
    }
  }


  return (
    <>
      <Modal className="new-book-item" show={show} onHide={close} animation={false}>
        <Modal.Body dir={langDirection}>
        <IconButton
          style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
          onClick={close}
        >
          <CloseIcon className="Header-close-modal-icon" />
        </IconButton>
        <div className="row">
          <div className="col-md-12">
            <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Item</h5>
          </div>
        </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Category</label>
                <select required className="form-control" onChange={(e) => setCategory(e.target.value)}>
                  <option value={``} selected disabled>{data?.category}</option>
                  <option value={`books`}>Books</option>
                  <option value={`documentary`}>Documentary</option>
                  <option value={`courses`}>Courses</option>
                  <option value={`news`}>News</option>
                  <option value={`ads`}>Ads</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Post Type</label>
                <select required className="form-control" onChange={(e) => setPostType(e.target.value)}>
                  <option value={``} selected disabled>{data?.media_type}</option>
                  <option value={`audio`}>Audio</option>
                  <option value={`video`}>Video</option>
                </select>
              </div>
            </div>



            <div className="col-md-6">
              <div className="form-group">
                <label>Tags</label>
                <Select
                  className='select-field'
                  defaultValue={renderListab()}
                  onChange={setSelectedOption}
                  options={listOfTags}
                  isMulti
                />

              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Title</label>
                <input type="text" className="form-control" defaultValue={data?.title}
                  onChange={(e) => setTitle(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Sub Title</label>
                <input type="text" className="form-control" defaultValue={data?.subtitle}
                  onChange={(e) => setSubTitle(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>ISBN Number</label>
                <input type="text" className="form-control" defaultValue={data?.isbn}
                  onChange={(e) => setIsbn(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Author</label>
                <input type="text" className="form-control" defaultValue={data?.authors}
                  onChange={(e) => setAuthor(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Details</label>
                <textarea className="form-control"
                  style={{ minHeight: '120px' }}
                  onChange={(e) => setDetails(e.target.value)}>
                  {data?.details}
                </textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: 'right' }}>
                <Button
                  style={{ background: '#2c2245cf', color: '#f7f7f7' }}
                  disabled={buttonDisabled()}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={() => { updateBook(updateBookData) }}>
                  {isLoading ? 'Loading...' : 'Submit'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateBookItem;
