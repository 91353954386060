import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Card, Dropdown, Badge, Modal, Accordion, DropdownButton } from 'react-bootstrap'
import './index.scss';
import { CKEditor } from 'ckeditor4-react';
import parse from "html-react-parser";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetBookByIdQuery,
  useUpdateBookMutation,
  useDeleteSummaryMutation,
  useConvertMediaMutation
} from '../../services/bookItems';
import UpdateBookItem from './UpdateBookItem';
import ImageUpdate from "./ImageUpdate";
import { message, Tag, Switch as Clickable } from "antd";
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import "react-dropzone-uploader/dist/styles.css";
import AddSummary from './AddSummary';
import UpdateSummary from './UpdateSummary';
import ViewSummary from './ViewSummary';

import LoadingButton from '@mui/lab/LoadingButton';
import StarIcon from '@mui/icons-material/Star';
import AudioPlayer from './AudioPlayer';
import SummaryList from './SummaryListaaa';
import IconButton from '@mui/material/IconButton';

function BookDetails() {
  const { userId, userToken, userEmail, userName, status, userRole, languageCode } = useSelector((state) => state.auth);
  const { bookId, itemId, language, query } = useParams();

  const { data: bookInfo, refetch, isFetching, isLoading } = useGetBookByIdQuery({
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    id: itemId,
    language: language,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const book = bookInfo && bookInfo?.book_item;
  const summaries = bookInfo && bookInfo?.book_item?.summaries;


  const [updateBook, updateresult] = useUpdateBookMutation()
  const [deleteSummary, deleteSummaryResult] = useDeleteSummaryMutation()

  const [convertmedia, convertresult] = useConvertMediaMutation()

  // update book item
  const [show, setShow] = useState(false);
  const [getBookItemUpdateData, setBookItemUpdateData] = useState()
  useEffect(() => {
    if (updateresult.isSuccess) {
      setShow(false)
    }
    if (convertresult?.isSuccess) {
      refetch()
    }
    refetch()
  }, [updateresult, convertresult, summaries, book]);



  function handleClose() {
    setShow(false)
  }
  function handleOpen(data) {
    setBookItemUpdateData(data)
    setShow(data.status)
  }


  // Add Summaries
  const [getSummaryModal, setSummaryModal] = useState(false);
  function addSummaries(data) {
    setSummaryModal(true)
  }


  // update summaries
  const [getSummaryUpdateModal, setSummaryUpdateModal] = useState(false);
  const [getSummaryUpdateData, setSummaryUpdateData] = useState([]);
  function handleOpenEdit(data) {
    setSummaryUpdateModal(true)
    setSummaryUpdateData(data)

  }


  // view book summaries
  const [getViewSummaryModal, setViewSummaryModal] = useState(false);
  const [getSummaryData, setSummaryData] = useState([]);
  function viewSummary(data) {
    setViewSummaryModal(true)
    setSummaryData(data)
  }



  // update the book image
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getImageUpdateData, setImageUpdateData] = useState([]);
  function imageUpdate(data) {
    setImageUpdateModal(data.status)
    setImageUpdateData(data)
  }


  // update the audios file
 

  const [hover, setHover] = useState(false);
  const [getHoverBookId, setHoverBookId] = useState(null);
  const [getHoverId, setHoverId] = useState(null);
  const onHover = (data) => {
    setHover(true);
    setHoverBookId(data.hoverBookId);
    setHoverId(data.hoverId);
  };

  const onLeave = () => {
    setHover(false);
  };

  function onClickDisabled() {
    updateBook({
      book_disable_id: book?.id,
      status: book?.status ? false : true,
      language: language,
      id: book?.id,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
      disable: 'disable'
    })
  }
  function onClickPopular() {
    updateBook({
      popular_id: book?.id,
      status: book?.popular ? false : true,
      language: language,
      id: book?.id,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
      popular: 'popular'
    })
  }

  function onClickAds() {
    updateBook({
      ads_id: book?.id,
      status: book?.ads ? false : true,
      language: language,
      id: book?.id,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
      ads: 'ads'
    })
  }

  function onClickTrending() {
    updateBook({
      trending_id: book?.id,
      status: book?.trending ? false : true,
      language: language,
      id: book?.id,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
      trending: 'trending'
    })
  }

  let langDirection;
  let langTextAlign;

  if (book?.language === 'ar') {
    langDirection = 'rtl'
    langTextAlign = 'right'
  } else if (book?.language === 'urdu') {
    langDirection = 'rtl'
    langTextAlign = 'right'
  } else if (book?.language === 'desi') {
    langDirection = 'ltr'
    langTextAlign = 'left'
  } else if (book?.language === 'en') {
    langDirection = 'ltr'
    langTextAlign = 'left'
  } else {
    langDirection = 'ltr'
    langTextAlign = 'left'
  }



  function diaplyStateAndStatus(state, status, length, reviewed) {
    let stateColor;
    let textColor
    if (status === 'in_progress') {
      stateColor = '#ffd65d'
      textColor = '#000'
    }
    else if (status === 'completed') {
      stateColor = '#72c585'
    }
    else if (status === 'on_hold') {
      stateColor = '#eb8188'
    }
    else if (!status) {
      stateColor = '#b7b7b7'
    }
    return (
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '7px', borderBottom: '1px solid #eee', paddingBottom: '7px' }}>
        <Tag className="booksTag" color={status ? stateColor : '#55acee'} style={{ color: textColor }}>
          {length > 0 ? state : 'not assigned'}
        </Tag>
        <StarIcon
          style={{ color: reviewed === 1 ? '#72c585' : '#eb8188', fontSize: '12px' }}
        />
      </div>
    )
  }


  function getFullLanguage(data) {
    if (data === 'en') {
      return 'English'
    } else if (data === 'ar') {
      return 'Arabic'
    } else if (data === 'desi') {
      return 'Desi'
    } else if (data === 'urdu') {
      return 'Urdu'
    } else {
      return 'NA'
    }
  }


  const showComponent = useMemo(() => {
    return <SummaryList summaries={summaries && summaries} book={book && book} language={language} viewSummary={viewSummary} deleteSummary={deleteSummary} deleteSummaryResult={deleteSummaryResult} handleOpenEdit={handleOpenEdit} convertmedia={convertmedia} convertresult={convertresult} />
  }, [summaries])


  const [getZoonView, setZoonView] = useState(false);
  function zoomImage() {
    setZoonView(true)
  }
  function zoomImageHide() {
    setZoonView(false)
  }




  return (
    <div className="tabel_cards Book-details">

      <Modal
        className="image-update-modal"
        show={getZoonView}
        onHide={zoomImageHide}
        animation={true}
      >
        <IconButton
          onClick={zoomImageHide}
          style={{ position: 'absolute', right: 0, top: 0, zIndex: 111 }}
          aria-label="fingerprint"
          color="success">
          <CloseIcon />
        </IconButton>
        <Modal.Body className="media-update-modal-section">
          <img src={book?.image ? `https://dj70nht8amdum.cloudfront.net/${book?.book_id}/${language}/${book?.id}/${book?.image}` : '/No-Image-Available-1.png'} width="160px" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>

      <UpdateBookItem
        show={show}
        close={handleClose}
        data={getBookItemUpdateData}
        updateBook={updateBook}
        isLoading={updateresult?.isLoading}
        itemId={itemId}
        language={language}
        langDirection={langDirection}
        bookTitle={book?.book?.title}
      />


      <ImageUpdate
        getImageUpdateModal={getImageUpdateModal}
        getImageUpdateData={getImageUpdateData}
        setImageUpdateModal={setImageUpdateModal}
      />

      <AddSummary
        getSummaryModal={getSummaryModal}
        setSummaryModal={setSummaryModal}
        langDirection={langDirection}
        itemId={itemId}
        book={book && book}
        summaries={summaries && summaries}
        language={language}
      />

      <UpdateSummary
        getSummaryUpdateModal={getSummaryUpdateModal}
        setSummaryUpdateModal={setSummaryUpdateModal}
        getSummaryUpdateData={getSummaryUpdateData}
        langDirection={langDirection}
        book={book && book}
        summaries={summaries && summaries}
        language={language}
      />
      <ViewSummary
        getViewSummaryModal={getViewSummaryModal}
        langDirection={langDirection}
        getSummaryData={getSummaryData}
        setViewSummaryModal={setViewSummaryModal}
        langTextAlign={langTextAlign}
      />

     

      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">

                <div className="updateButton">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
 

                  </div>
                  <div className="table_search_card mrgnRight">
                    <Button
                      style={{textTransform: 'capitalize'}}
                      color="warning"
                      variant="contained"
                      size="small"
                      onClick={() => addSummaries(true)}>
                       Create New LiT
                    </Button>
                  </div>
                </div>

                <div className="ckEditorWarm">
                  <CKEditor config={{
                    // extraPlugins: 'codemirror,dragresize',
                    extraPlugins: "justify,showblocks",
                    toolbar: [
                      ['Source'],
                      ['Styles', 'Format', 'Font', 'FontSize'],
                      ['Bold', 'Italic'],
                      ['Undo', 'Redo'],
                      ['EasyImageUpload'],
                      ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                      ['Maximize']
                    ],
                    height: '220px',
                    allowedContent: false,
                    direction: langDirection,
                    language: book?.language === 'ar' || book?.language === 'urdu' ? 'ar' : 'en',
                    pasteFromWordRemoveFontStyles: false
                  }} id="template_html" data='hello world!' onChange={''} initData={'Hello World!'} />
                </div>
                <div className="book-details-section">
                  <div className="row">
                    <div className="col-md-6 List-Book-card">
                      <div className="List-Book" style={{ display: 'block' }}>
                        <div className="Book-list-text">
                          {book?.title ?
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', }}>
                              <IconButton
                                onClick={() => handleOpen({
                                  "status": true,
                                  "title": book?.title,
                                  "subtitle": book?.subtitle,
                                  "isbn": book?.isbn,
                                  "authors": book?.authors,
                                  "details": book?.details,
                                  "media_type": book?.media_type,
                                  "category": book?.category,
                                  "id": book?.id,
                                  "tags": book?.tags
                                })}
                              >
                                <EditIcon style={{ fontSize: '14px' }} />
                              </IconButton>
                              <h5 style={{ margin: 0 }}>{book?.title}</h5>
                            </div> :
                            <Skeleton height={15} />}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div style={{ position: "relative" }}>
                            <img
                              onClick={zoomImage}
                              src={book?.image ? `https://dj70nht8amdum.cloudfront.net/${book?.book_id}/${language}/${book?.id}/${book?.image}` : '/No-Image-Available-1.png'}
                              style={{ border: '1px solid #eee', maxWidth: '100%', width: '130px', cursor: 'pointer', borderTopLeftRadius: '100px' }} />
                            <IconButton
                              style={{ position: 'absolute', left: 0, top: 0 }}
                              onClick={() =>
                                imageUpdate({
                                  status: true,
                                  book_id: book?.book_id,
                                  id: book?.id,
                                  language: book?.language
                                })}>
                              <EditIcon style={{ fontSize: '14px', color: '#2c2245' }} />
                            </IconButton>
                          </div>


                          {book?.category === 'ads' ?
                            <div className="List-Book" style={{ justifyContent: 'space-between', padding: '10px 0px 0px 0px', alignItems: 'center' }}>
                              <small className="Book-list-heading">Ads:</small>
                              <span style={{ display: 'flex', justifyContent: 'right' }}>
                                {book && book ?
                                  <Clickable
                                    size="small"
                                    style={{ width: '10px' }}
                                    disabled={userRole === 'admin' ? false : true}
                                    defaultChecked={book?.ads ? true : false}
                                    onClick={() => userRole === 'admin' && onClickAds()}
                                  /> :
                                  <Skeleton height={15} width={70} />
                                }
                              </span>
                            </div>
                            :
                            <>
                              <div className="List-Book" style={{ justifyContent: 'space-between', padding: '10px 0px 0px 0px', alignItems: 'center' }}>
                                <small className="Book-list-heading">Published:</small>
                                <span style={{ display: 'flex', justifyContent: 'right' }}>
                                  {book && book ?
                                    <Clickable
                                      size="small"
                                      disabled={userRole === 'admin' ? false : true}
                                      defaultChecked={book?.status ? true : false}
                                      onClick={() => userRole === 'admin' && onClickDisabled()}
                                    /> :
                                    <Skeleton height={15} width={70} />
                                  }
                                </span>
                              </div>

                              <div className="List-Book" style={{ justifyContent: 'space-between', padding: '10px 0px 0px 0px', alignItems: 'center' }}>
                                <small className="Book-list-heading">Trending:</small>
                                <span style={{ display: 'flex', justifyContent: 'right' }}>
                                  {book && book ?
                                    <Clickable
                                      size="small"
                                      disabled={userRole === 'admin' ? false : true}
                                      defaultChecked={book?.trending ? true : false}
                                      onClick={() => userRole === 'admin' && onClickTrending()}
                                    /> :
                                    <Skeleton height={15} width={70} />
                                  }
                                </span>
                              </div>
                              <div className="List-Book" style={{ justifyContent: 'space-between', padding: '10px 0px 0px 0px', alignItems: 'center' }}>
                                <small className="Book-list-heading">Popular:</small>
                                <span style={{ display: 'flex', justifyContent: 'right' }}>
                                  {book && book ?
                                    <Clickable
                                      size="small"
                                      style={{ width: '10px' }}
                                      disabled={userRole === 'admin' ? false : true}
                                      defaultChecked={book?.popular ? true : false}
                                      onClick={() => userRole === 'admin' && onClickPopular()}
                                    /> :
                                    <Skeleton height={15} width={70} />
                                  }
                                </span>
                              </div>
                            </>
                          }


                          <div style={{ padding: '10px 0px 10px 0px' }}>
                            {book?.tasks?.length > 0 &&
                              <>
                                {book?.tasks?.map((taskItem, i) =>
                                  <div>
                                    {diaplyStateAndStatus(taskItem.state, taskItem.status, book.tasks?.length, taskItem.reviewed)}
                                  </div>
                                )}
                              </>
                            }
                            {book?.tasks?.length === 0 &&
                              <Tag className="booksTag" color={'#b7b7b7'}>
                                Not Assigned
                              </Tag>
                            }
                          </div>

                        </div>
                        <div className="col-md-9">
                          <div style={{ width: '100%' }}>
                            {book?.category !== 'ads' &&
                              <div style={{ paddingBottom: '10px' }}>
                                <span className='Book-list-heading'>Tags</span>
                                <>
                                  {book?.tags ?
                                    <div style={{ whiteSpace: 'normal' }}>
                                      {book?.tags?.split(',').map((concept, i) =>
                                        <>
                                          <Tag className="booksTag" onMouseEnter={() => onHover({ hoverBookId: book?.id, hoverId: i })}
                                            onMouseLeave={onLeave} color="#55acee">
                                            {concept}
                                            {hover && (getHoverBookId === book?.id && getHoverId === i) &&
                                              <div className="booksTagsDelete" onClick={() =>
                                                updateBook({
                                                  "update_tag": book?.tags?.split(',').filter(n => !concept.split(',').includes(n)).join(','),
                                                  language: language,
                                                  id: book?.id,
                                                  userId: userId,
                                                  userToken: userToken,
                                                  userRole: userRole,
                                                })}><CloseIcon /></div>}
                                          </Tag>
                                        </>
                                      )}
                                    </div> :
                                    <Skeleton animation="wave" width={'50%'} />
                                  }
                                </>
                              </div>
                            }
                            <div style={{ width: '100%' }}>
                              {book?.category !== 'ads' &&
                                <>
                                  <div className="List-Book">
                                    <span className="Book-list-heading">ISBN:</span>
                                    <span className="Book-list-text">{book?.isbn ? book?.isbn : <Skeleton height={15} />}</span>
                                  </div>
                                  <div className="List-Book">
                                    <span className="Book-list-heading">Author:</span>
                                    <span className="Book-list-text">{book?.authors ? book?.authors : <Skeleton height={15} />}</span>
                                  </div>
                                </>
                              }
                              <div className="List-Book">
                                <span className="Book-list-heading">Language:</span>
                                <span className="Book-list-text">{book?.language ? getFullLanguage(book?.language) : <Skeleton height={15} />}</span>
                              </div>
                              <div className="List-Book">
                                <span className="Book-list-heading">Type:</span>
                                <span className="Book-list-text">{book?.media_type ? book?.media_type : <Skeleton height={15} />}</span>
                              </div>
                              <div className="List-Book">
                                <span className="Book-list-heading">Category:</span>
                                <span className="Book-list-text">{book?.category ? book?.category : <Skeleton height={15} />}</span>
                              </div>
                              <div className="List-Book">
                                <span className="Book-list-heading">Date:</span>
                                <span className="Book-list-text">{book?.createdAt ? book?.createdAt : <Skeleton height={15} />}</span>
                              </div>
                              <div className="List-Book">
                                <span className="Book-list-heading">By:</span>
                                <span className="Book-list-text">{book?.user?.email ? book?.user?.email : <Skeleton height={15} />}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>

                        <div className="List-Book" style={{ display: 'block' }}>
                          <div className="Book-list-heading">Sub Title:</div>
                          <div className="Book-list-text">
                            {book?.subtitle ? book?.subtitle : <Skeleton height={15} />}
                          </div>
                        </div>
                        <div className="List-Book" style={{ display: 'block' }}>
                          <div className="Book-list-heading">Details:</div>
                          <div className="Book-list-text">{book?.details ? book?.details : <Skeleton height={15} />}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">

                      {showComponent}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookDetails;
