import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Category'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getAllCategory: builder.query({
      query: (data) => ({
        url: `categories/${data.language}?page=${data.page}&size=${data.size}&searchid=${data.searchid}`,
        method: 'GET',
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Category'],
    }),

    createCategory: builder.mutation({
      query: (data) => ({
        url: `categories/${data.language}`,
        method: 'POST',
        body: data,
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Category'],
    }),


    updateCategory: builder.mutation({
      query: (data) => {
        return {
          url: `categories/${data.language}/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Category'],
    }),

    updateImage: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("myFile", data.image_file);
        return {
          url: `categories/${data.language}/${data.category_id}`,
          method: 'POST',
          body: formData,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Category'],
    }),

    deleteMedia: builder.mutation({
      query: (data) => {
        return {
          url: `categories/${data.language}/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Category'],
    }),



  }),

})

export const { useGetAllCategoryQuery, useUpdateImageMutation, useCreateCategoryMutation, useUpdateCategoryMutation, useDeleteMediaMutation } = categoriesApi