import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { Modal, Spinner } from 'react-bootstrap'
import TableSkeleton from "./TableSkeleton";
import SearchIcon from "@material-ui/icons/Search";
import { Tag, Menu, Dropdown, DatePicker, Space } from "antd";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import {
  useUpdateTaskMutation,
  useGetTaskListQuery,
  useGetSearchUserQuery,
  useCreateTaskCommentMutation
} from '../../services/listing';
// import Pagination from "../Pagination";
import './index.scss';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReviewsIcon from '@mui/icons-material/Reviews';
import moment from 'moment';
import Loader from '../../Helper/Loader';
import Select from 'react-select';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
function BookTasks() {
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const [getSearchId, setSearchId] = useState("")
  const getAssignee = new URLSearchParams(location.search).get('assignee') || ''
  const getStatus = new URLSearchParams(location.search).get('status') || ''




  const [getAssigneeName, setAssigneeName] = useState('');
  const customFilter = (selectedOption) => {
    setAssigneeName(selectedOption)
  };

  // get assignee data
  const userSearchData = useGetSearchUserQuery({
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    query: getAssigneeName,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const userData = userSearchData?.data && userSearchData?.data?.user;


  function renderList() {
    return (userData?.map(data => ({
      label: data.email ? data.email : data.username,
      value: data.email ? data.email : data.username,
      assigneeId: data.id
    })))
  }




  function removeAssigneeQuery() {
    history.push(`${window.location.pathname}`);
  }


  let assigneeGet
  if (getAssignee === null) {
    assigneeGet = '';
  } else {
    assigneeGet = `&assignee=${getAssignee}`
  }


  const { data: tasks, isFetching, isLoading, isSuccess, refetch } = useGetTaskListQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    searchid: getSearchId,
    status: getStatus
  }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const pagination = tasks?.pagination;


  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     refetch()
  //   }, 300000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);




  const [taskUpdate, taskUpdateResult] = useUpdateTaskMutation()




  const [getTaskComment, setTaskComment] = useState('');
  const [getTaskFile, setTaskFile] = useState('');

  const [createcomments, commentsresult] = useCreateTaskCommentMutation()
  useEffect(() => {
    if (commentsresult?.isSuccess) {
      setTaskComment('')
      setTaskFile('')
    }
  }, [commentsresult]);

  function displayStatus(data) {
    let color;
    let name;
    if (data === 'in_progress') {
      color = '#ffd65d'
      name = 'In-Progress'
    }
    else if (!data) {
      color = '#ffd65d'
      name = 'Pending'
    }
    else if (data === 'completed') {
      color = '#72c585'
      name = 'Completed'
    }
    else if (data === 'on_hold') {
      color = '#eb8188'
      name = 'On Hold'
    }
    return (
      <Tag className="booksTag" color={color}>
        {name}
      </Tag>
    )
  }


  function displayStatusColor(data) {
    if (data === 'in_progress') {
      return '#ffd65d'
    }
    else if (!data) {
      return '#ffd65d'
    }
    else if (data === 'completed') {
      return '#72c585'
    }
    else if (data === 'on_hold') {
      return '#eb8188'
    }
  }



  function getNumberOfDays(startDay, endDay) {
    const startDate = moment(startDay, 'DD-MM-YYYY');
    const endDate = moment(endDay, 'DD-MM-YYYY');
    const days = endDate.diff(startDate, 'days');
    if (endDate > startDate) {
      return days + " Day(s)"
    }
    else if (startDay && startDay === endDay && endDay) {
      return 1 + ' ' + " Day(s)"
    }
    else if (startDate && !endDate) {
      return days + " Day(s)"
    }
    else if (!startDate && !endDate) {
      return ''
    } else {
      return ''
    }
  }


  // open task assignee modal
  const [getTasksData, setTasksData] = useState([])
  const [getTaskModal, setTaskModal] = useState(false)
  function openTaskModal(data) {
    setTaskModal(data.status)
    setTasksData(data)
  }
  function closeTaskModal(data) {
    setTaskModal(false)
  }


  function getLanguageName(language) {
    let name;
    if (language === 'en') {
      name = 'English'
    } else if (language === 'ar') {
      name = 'Arabic'
    }
    else if (language === 'desi') {
      name = 'Desi'
    }
    else if (language === 'urdu') {
      name = 'Urdu'
    } else {
      name = 'NA'
    }
    return (
      <div><small><strong>Language:&nbsp; </strong>{name}</small></div>
    )
  }


  function startProject(data) {
    taskUpdate({
      start_task: moment(data?.date).format("DD/MM/YYYY"),
      id: data.id,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
      startLoader: 'startLoader'
    })
  }
  function endProject(data) {
    taskUpdate({
      end_task: moment(data?.date).format("DD/MM/YYYY"),
      id: data.id,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
      endLoader: 'endLoader'
    })
  }

  const [getSelectedStatus, setSelectedStatus] = useState([])
  useEffect(() => {
    if (getSelectedStatus?.value) {
      taskUpdate({
        status: getSelectedStatus?.value,
        id: getSelectedStatus?.id,
        start_date: getSelectedStatus?.start_date,
        end_date: getSelectedStatus?.end_date,
        date: moment().format("DD/MM/YYYY"),
        userId: userId,
        userToken: userToken,
        userRole: userRole,
        task_status: getSelectedStatus?.task_status,
      })
    }
    if (taskUpdateResult?.isSuccess) {
      setSelectedStatus([0])
    }
  }, [getSelectedStatus?.value, taskUpdateResult?.isSuccess]);


  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 90%) !important',
      boxShadow: 'none !important',
      minWidth: '140px',
      maxWidth: '100%'
    }),
    option: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer'
    }),
    menu: styles => ({
      ...styles,
      width: '100%'
    })
  };
  function getStatusLabel(data) {
    return (
      <div style={{ with: '100%', cursor: 'pointer' }}>{data}</div>
    )
  }


  function getFullLanguage(data) {
    if (data === 'en') {
      return 'english'
    } else if (data === 'ar') {
      return 'arabic'
    } else if (data === 'desi') {
      return 'desi'
    } else if (data === 'urdu') {
      return 'urdu'
    } else {
      return 'missing'
    }
  }



  return (
    <div className="tabel_cards books-page">


      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">
                <div className="updateButton">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '10px', position: 'relative' }}>
                      <input className="form-control table_search_task" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                      {/* {isFetching &&
                        <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                      } */}
                      <SearchIcon className="table_search_icon" />
                    </div>

                    <>
                      <div>


                        <Select
                          styles={customStyles}
                          isDisabled={false}
                          isClearable={false}
                          defaultValue={getStatus}
                          onChange={((value) =>
                            history.push(`${window.location.pathname}${`?page=${1}`}${value ? `&status=${value.value}` : ''}`)
                          )}
                          placeholder={`Status:` + ' ' + getStatus}
                          className='select-options'
                          options={
                            [
                              { label: getStatusLabel('All'), value: '', isDisabled: getStatus === '' ? true : false },
                              { label: getStatusLabel('In Progress'), value: 'in_progress', isDisabled: getStatus === 'in_progress' ? true : false },
                              { label: getStatusLabel('On Hold'), value: 'on_hold', isDisabled: getStatus === 'on_hold' ? true : false },
                              { label: getStatusLabel('Completed'), value: 'completed', isDisabled: getStatus === 'completed' ? true : false }
                            ]
                          }
                        />

                      </div>

                    </>
                  </div>
                  <div className="table_search_card">
                    <div>
                    </div>

                  </div>
                </div>

                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}${getStatus ? `&status=` + getStatus : ''}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
                <div className="tabelOverflow">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}></th>
                        <th>#</th>
                        <th>Title</th>
                        <th>Language</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Days</th>
                        <th>Review</th>
                        <th className="sticky-column" style={{ textAlign: 'right' }}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {isLoading &&
                          <TableSkeleton />
                        }
                      </>
                      {tasks?.tasks?.map((item, i) =>
                        <>
                          <tr key={i}>
                            <td>
                              <div>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(() => openTaskModal({
                                    status: true,
                                    taskId: item.id,
                                  }))}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </div>
                            </td>
                            <td>{item.book_item_id}</td>
                            <td style={{ position: 'relative' }}>
                              <div style={{ whiteSpace: 'normal', width: '320px' }}>{item.book_item?.title}</div>
                            </td>
                            <td>
                              {getFullLanguage(item.book_item?.language)}
                            </td>
                            <td>
                              {item.start_date && item.start_date}
                            </td>
                            <td>
                              {item.end_date && item.end_date}
                            </td>
                            <td>
                              {getNumberOfDays(item.start_date, item.end_date ? item.end_date : moment().format("DD/MM/YYYY"))}
                            </td>
                            <td>
                              <div>
                                <Tag
                                  color={item.reviewed === 1 ? '#72c585' : 'rgb(255 150 93)'}>
                                  {item.reviewed === 1 ? 'Reviewed' : 'In Review'}
                                </Tag>
                              </div>
                            </td>
                            <td className="sticky-column" style={{ textAlign: 'right', width: '1px' }}>
                              <div>
                                {item.state.split(',').map((concept, i) =>
                                  <>
                                    <Tag
                                      style={{ textTransform: 'capitalize' }}
                                      className="booksTag"
                                      color={displayStatusColor(item.status)}>
                                      {!concept ? 'Pending' : concept.split('_').join(' ')}
                                    </Tag>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>

                          <Modal className="task-Modal" show={getTasksData?.taskId === item.id ? getTaskModal : false} onHide={closeTaskModal} animation={false}>
                            {/* <Modal.Header closeButton style={{ display: 'flex', alignItems: 'center' }}>
                              <Modal.Title>{item.assignee}</Modal.Title>
                            </Modal.Header> */}
                            <div>
                              <IconButton
                                style={{ position: 'absolute', right: '5px', zIndex: 111, top: '5px' }}
                                aria-label="delete"
                                onClick={closeTaskModal}>
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <Modal.Body>
                              <br />
                              <div className="row">
                                <div className="col-md-8">
                                  <div style={{ display: 'flex', paddingBottom: '20px' }}>
                                    <div>
                                      <div>
                                        <h6><strong>{item.book_item?.title} #{item.book_item?.id}</strong></h6>
                                        <p><small><strong>ISBN:&nbsp; </strong>{item.book_item?.isbn}</small></p>
                                      </div>
                                      <div>
                                        <div><small><strong>Authors:&nbsp; </strong>{item.book_item?.authors}</small></div>
                                      </div>
                                      <div>
                                        {getLanguageName(item.book_item?.language)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div style={{ whiteSpace: 'normal' }}>
                                      {item.book_item?.tags?.split(',').map((concept, i) =>
                                        <>
                                          <Tag className="booksTag" color="#55acee">
                                            {concept}
                                          </Tag>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className='form-group'>
                                    <label>Comment</label>
                                    <textarea
                                      className='form-control'
                                      onChange={(e) => setTaskComment(e.target.value)}
                                    >
                                    </textarea>
                                  </div>
                                  <div className='form-group'>
                                    <input
                                      className='form-control'
                                      type="file"
                                      onChange={(e) => setTaskFile(e.target.files[0])}
                                    />
                                  </div>
                                  <div className='form-group'>
                                    <Button
                                      disabled={commentsresult?.isLoading ? true : false}
                                      color="info"
                                      variant="contained"
                                      size="large"
                                      style={{ display: 'flex' }}
                                      onClick={() => createcomments({
                                        task_file: getTaskFile,
                                        task_comment: getTaskComment,
                                        userId: userId,
                                        userToken: userToken,
                                        userRole: userRole,
                                        id: item.id
                                      })}
                                    >
                                      {commentsresult?.isLoading ? 'Loading...' : 'Submit'}
                                    </Button>
                                  </div>

                                  {item.task_comments?.map((task_comment, i) =>
                                    <div
                                      key={i}
                                      style={{ padding: '10px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '4px', marginBottom: '10px' }}>
                                      <div>{task_comment.comment}</div>
                                      <div>
                                        <a href={`https://m.ebtida.com/d/${item.id}/${JSON.parse(task_comment.file)}`} target='_blank'>
                                          {JSON.parse(task_comment.file)}
                                        </a>
                                      </div>
                                    </div>

                                  )}
                                </div>





                                <div className="col-md-4">
                                  <div className="form-group" style={{ borderBottom: '1px solid #eee', paddingBottom: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                                      <span>
                                        <span style={{ marginRight: '10px' }}>
                                          {item.start_date ? 'Started Date' : 'Start Date'}
                                        </span>
                                        <span>
                                          {item.start_date &&
                                            <Tag className="booksTag" color={"#55acee"}>
                                              {item.start_date}
                                            </Tag>
                                          }
                                        </span>
                                      </span>
                                      <span>{(taskUpdateResult?.isLoading) && (taskUpdateResult?.originalArgs?.startLoader === 'startLoader') && <Loader />}</span>
                                    </div>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                      <DatePicker disabled={item.start_date ? true : false} onChange={((date, dateString) => !item.start_date && startProject({ date: date, dateString: dateString, id: item.id }))} className="datePicker" style={{ width: '100%' }} />
                                    </Space>
                                  </div>

                                  <div className="form-group" style={{ borderBottom: '1px solid #eee', paddingBottom: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>

                                      <span>
                                        <span style={{ marginRight: '10px' }}>
                                          {item.end_date ? 'Ended Date' : 'End Date'}
                                        </span>
                                        <span>
                                          {item.end_date &&
                                            <Tag className="booksTag" color={"#55acee"}>
                                              {item.end_date}
                                            </Tag>
                                          }
                                        </span>
                                      </span>
                                      <span>{(taskUpdateResult?.isLoading) && (taskUpdateResult?.originalArgs?.endLoader === 'endLoader') && <Loader />}</span>
                                    </div>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                      <DatePicker disabled={item.end_date ? true : false} onChange={((date, dateString) => !item.end_date && endProject({ date: date, dateString: dateString, id: item.id }))} className="datePicker" style={{ width: '100%' }} />
                                    </Space>

                                  </div>

                                  <div className="form-group" style={{ borderBottom: '1px solid #eee', paddingBottom: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                                      <span>
                                        <span style={{ marginRight: '10px' }}>
                                          Status
                                        </span>
                                        <span>
                                          {displayStatus(item.status)}
                                        </span>
                                      </span>
                                      <span>{(taskUpdateResult?.isLoading) && (taskUpdateResult?.originalArgs?.task_status === 'task_status') && <Loader />}</span>
                                    </div>
                                    <Select
                                      isDisabled={item.status === 'completed' ? true : false}
                                      isLoading={(taskUpdateResult?.isLoading) && (taskUpdateResult?.originalArgs?.task_status === 'task_status') ? true : false}
                                      isClearable={false}
                                      onChange={setSelectedStatus}
                                      defaultValue={getSelectedStatus}
                                      options={
                                        [
                                          { label: 'Select', value: '', isDisabled: true },
                                          { label: 'In Progress', value: 'in_progress', id: item.id, start_date: item.start_date, end_date: item.end_date, isDisabled: item.status === 'in_progress' ? true : false, task_status: 'task_status' },
                                          { label: 'Completed', value: 'completed', id: item.id, start_date: item.start_date, end_date: item.end_date, isDisabled: item.status === 'completed' ? true : false, task_status: 'task_status' },
                                          { label: 'On Hold', value: 'on_hold', id: item.id, start_date: item.start_date, end_date: item.end_date, isDisabled: item.status === 'on_hold' ? true : false, task_status: 'task_status' },

                                        ]
                                      }
                                    />

                                  </div>

                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>

                        </>
                      )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookTasks;
