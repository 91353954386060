import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import { useGetAllMediaQuery, useUpdateMediaMutation, useDeleteMediaMutation } from '../../services/media';
import 'react-h5-audio-player/lib/styles.css';
import ImageUpdate from "./ImageUpdate";
import IconButton from '@mui/material/IconButton';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import Button from '@mui/material/Button';
import CloseIcon from "@material-ui/icons/Close";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Media() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const location = useLocation();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const getLanguage = new URLSearchParams(location.search).get('language') || 'en'

  let languageGet
  if (getLanguage === null) {
    languageGet = '';
  } else {
    languageGet = `&language=${getLanguage}`
  }

  const [getSearchId, setSearchId] = useState("")

  const responseInfo = useGetAllMediaQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  },
    { count: 1 },
    { refetchOnMountOrArgChange: true })
  const media = responseInfo?.data && responseInfo?.data?.media;
  const pagination = responseInfo?.data && responseInfo?.data?.pagination;

  const [deleteMedia, deleteresult] = useDeleteMediaMutation()
  const [updateImage, imageresult] = useUpdateMediaMutation()

  const [getUploadingStatus, setUploadingStatus] = useState("");
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getImageError, setImageError] = useState("");
  const [getUpdateImage, setUpdateImage] = useState("");
  const [getImageBookId, setImageBookId] = useState("");


  function updateBookImage() {
    updateImage({
      image_file: getUpdateImage,
      category_id: getImageBookId,
      languageCode: getLanguage,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
    })
  }
  useEffect(() => {
    if (imageresult.isSuccess) {
      setImageUpdateModal(false)
    }
  }, [imageresult]);
  function imageUpdate(data) {
    setImageUpdateModal(true)
    setImageBookId(data.category_id)
  }
  function closeImageUpdate() {
    setImageUpdateModal(false)
  }

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };


  function displayMediaFile(type, media) {
    if (type.includes('image')) {
      return (
        <img src={`https://cdn.ebtida.com/${media}`} width={'100%'} />
      )
    } else if (type.includes('video')) {
      return (
        <video width="320" height="240" controls>
          <source src={`https://cdn.ebtida.com/${media}`} type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
        </video>
      )
    }
  }


  function importModal() {
    if (getImageUpdateModal === 'uploading') {
      return true
    } else if (imageresult?.isLoading) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="tabel_cards category-page">

      <Modal
        className="image-update-modal-video"
        show={getImageUpdateModal}
        onHide={closeImageUpdate}
        animation={false}
        backdrop={importModal() ? 'static' : 'dynamic'}
      >
     
        <Modal.Body>

        <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={importModal() ? '' : closeImageUpdate}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Add Media</h5>
            </div>
          </div>

          <div>{getImageError && getImageError}</div>
          <ImageUpdate
            setUploadingStatus={setUploadingStatus}
            setUpdateImage={setUpdateImage}
            imageresult={imageresult}
            updateBookImage={updateBookImage}
          />
        </Modal.Body>
      </Modal>

      {/* {responseInfo.isLoading && <div className="loadingBorder">&nbsp;</div>} */}
      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">
                <div className="updateButton">
                  <div className='dFlex'>
                    <div style={{ position: 'relative' }}>
                      <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                      {responseInfo?.isFetching &&
                        <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                      }
                      <SearchIcon className="table_search_icon" />
                    </div>

                  </div>
                  <div className="table_search_card">
                    <div>
                      <Button
                        color="warning"
                        variant="contained"
                        size="small"
                        onClick={() => imageUpdate(true)}>
                        Add New
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="newFlexPagi">
                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={responseInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
                <div className="row" style={{padding: '10px'}}>

                  {responseInfo?.isLoading &&
                    <>
                      <div className="col-md-3 media-card">
                        <div className="media-subcard">
                          <Skeleton variant="rect" height={200} />
                        </div>
                        <Skeleton variant="rect" />
                        <Skeleton variant="rect" />
                      </div>
                      <div className="col-md-3 media-card">
                        <div className="media-subcard">
                          <Skeleton variant="rect" height={200} />
                        </div>
                        <Skeleton variant="rect" />
                        <Skeleton variant="rect" />
                      </div>
                      <div className="col-md-3 media-card">
                        <div className="media-subcard">
                          <Skeleton variant="rect" height={200} />
                        </div>
                        <Skeleton variant="rect" />
                        <Skeleton variant="rect" />
                    </div>
                    <div className="col-md-3 media-card">
                        <div className="media-subcard">
                          <Skeleton variant="rect" height={200} />
                        </div>
                        <Skeleton variant="rect" />
                        <Skeleton variant="rect" />
                      </div>
                    </>
                  }

                  {media?.map((item, i) =>
                    <div className="col-md-3 media-card" key={i}>

                      <div className="media-subcard">
                        <div>
                          {displayMediaFile(item.type, item.name)}
                        </div>

                        <div style={{ wordBreak: 'break-all', fontSize: '12px' }}>
                          <a href={`https://cdn.ebtida.com/${item.name}`} target="_blank">
                            {`https://cdn.ebtida.com/${item.name}`}
                          </a>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <div style={{ fontSize: '12px' }}>{item.createdAt}</div>

                          <div className="manage-buttons" style={{ justifyContent: 'right' }}>
                            {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ? 'deleting...' :
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={() => deleteMedia({
                                  id: item.id,
                                  name: item.name,
                                  userId: userId,
                                  userToken: userToken,
                                  userRole: userRole,
                                })}
                              >
                                <DoDisturbOnIcon style={{ color: '#d04848' }} />
                              </IconButton>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;
