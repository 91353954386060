import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import UsersSkeleton from "./UsersSkeleton";
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/team';
// import Pagination from "../Pagination";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { message, Tag, Menu, Dropdown, Switch as Clickable } from "antd";
import MenuItem from '@mui/material/MenuItem';
import Moment from 'moment';
import Select from 'react-select';
import Loader from '../../Helper/Loader';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
const KEY = "ADD_PRODUCTS";
function Team() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')

  const othersUserData = {
    name: getName,
    username: getUsername,
    reference: getReference
  }

  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.users;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;


  const [createUser, createresult] = useCreateUserMutation()
  const [updateUser, updateresult] = useUpdateUserMutation()


  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }


  function othersEModal() {
    setOthersModal(true)
  }
  function closeOthersModal() {
    setOthersModal(false)
  }


  const [getUserUpdateModal, setUserUpdateModal] = useState(false)
  const [getUserUpdateData, setUserUpdateData] = useState()
  const [getUserRole, setUserRole] = useState('')
  function updateUserModal(data) {
    setUserUpdateModal(data.status)
    setUserUpdateData(data)
  }
  function closeUserUpdateModal() {
    setUserUpdateModal(false)
  }

  const bookItemData = {
    user_role: getUserRole,
    id: getUserUpdateData?.id,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
      reset()
    }
    if (updateresult?.isSuccess) {
      setUserUpdateModal(false)
    }
  }, [updateresult, createresult]);



  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto'
    }),
    option: styles => ({
      ...styles,
      width: 'auto'
    }),
    menu: styles => ({
      ...styles,
      width: 'auto'
    })

  };

  function displayUserRole(role) {
    let name;
    if (role === 'admin') {
      name = 'Master'
    } else if (role === 'task') {
      name = 'Task'
    } else if (role === 'default') {
      name = ''
    } else {
      name = role
    }
    return name;

  }



  return (
    <div className="tabel_cards">
      <Modal className="user-page-modal" show={getUserUpdateModal} onHide={closeUserUpdateModal} animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeUserUpdateModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Access</label>
                <select className="form-control" onChange={(e) => setUserRole(e.target.value)}>
                  <option value={getUserUpdateData?.user_role} selected disabled>{getUserUpdateData?.user_role}</option>
                  <option value="task">Task</option>
                  <option value="admin">Master</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  style={{ width: '100%', background: '#2c2245cf', color: '#f7f7f7' }}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={() => !updateresult.isLoading && updateUser(bookItemData)}>
                  {updateresult.isLoading ? <Loader /> : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>




      <Modal className="user-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeNewModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>New</h5>
            </div>
          </div>

          <form onSubmit={handleSubmit(createUser)}>
            {createresult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                <span className="alert alert-danger">{createresult?.error?.data?.error}</span>
              </div>
            }
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Reference</label>
                  <input type="text" className="form-control"
                    id="reference"
                    aria-invalid={errors.reference ? "true" : "false"}
                    {...register('reference', { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Role</label>
                  <select className="form-control"
                    id="user_role"
                    aria-invalid={errors.user_role ? "true" : "false"}
                    {...register('user_role', { required: true })}>
                    <option value="" selected disabled>Select Role</option>
                    <option value="task">Task</option>
                    <option value="admin">Master</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" className="form-control"
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" autoComplete="off" className="form-control"
                    id="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                  {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                  {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                  {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Button
                    style={{ width: '100%', background: '#2c2245cf', color: '#f7f7f7' }}
                    disabled={createresult.isLoading ? true : false}
                    color="info"
                    variant="contained"
                    size="medium"
                    type="submit">
                    {createresult.isLoading ? 'Loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>






      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>

              <div className="storeCardB">
                <div className="updateButton">
                  <div style={{ position: 'relative' }}>
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                    {usersInfo?.isFetching &&
                      <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                    }
                    <SearchIcon className="table_search_icon" />
                  </div>
                  <div className="table_search_card">
                    <div>

                      <Button
                        onClick={() => ebtidaEModal(true)}
                        color="warning"
                        variant="contained"
                        size="small"
                      >
                        Add New
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={usersInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
                <div className="tabelOverflow">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}></th>
                        <th>#</th>
                        <th>Email</th>
                        <th>Reference</th>
                        <th>Mobile</th>
                        <th>Role</th>
                        <th>Updated At</th>
                        <th className="sticky-column" style={{ textAlign: 'right' }}>
                          Status
                        </th>

                      </tr>
                    </thead>
                    {users?.length === 0 ?
                      <h5 className="data_not-found">No data available in table</h5>
                      : null}
                    <tbody>
                      <>
                        {usersInfo?.isLoading &&
                          <UsersSkeleton />
                        }
                      </>
                      {users?.map((item, i) =>
                        <tr key={i}>
                          <td>
                            {item.email !== 'admin@admin.com' &&
                              <div>

                                <a onClick={e => e.preventDefault()}>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={() => updateUserModal({
                                      id: item.id,
                                      status: true,
                                      user_role: item.user_role,
                                    })}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </a>
                              </div>
                            }
                          </td>
                          <td>
                            <div>{item.id}</div>
                          </td>
                          <td>
                            <div>
                              <div> {item.name && item.name + ', '}{item.email ? item.email : item.username}</div>
                            </div>
                          </td>
                          <td>
                            {item.reference &&
                              <Tag className="booksTag" color={"#55acee"}>
                                <small>{item.reference}</small>
                              </Tag>
                            }
                          </td>
                          <td>
                            <div>{item.phone}</div>
                          </td>
                          <td>
                            <div>
                              {displayUserRole(item.user_role)}
                            </div>
                          </td>
                          <td>
                            <div>{Moment(item.createdAt).format('DD-MM-YYYY')}</div>
                          </td>
                          <td className="sticky-column" style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                              {item.state &&
                                <Tag className="booksTag" color={"#7dc487"}>
                                  <small>{item.state}</small>
                                </Tag>
                              }

                              <Tag className={item.status === 1 ? "booksTag" : "booksTagE"} color={item.status === 1 ? '#72c585' : '#eb8188'}
                                onClick={() => item.email !== 'admin@admin.com' && updateUser({
                                  id: item.id,
                                  status: item.status ? 0 : 1,
                                  status_update: 'status_update',
                                  userId: userId,
                                  userToken: userToken,
                                  userRole: userRole
                                })}
                              >
                                <small>{updateresult?.isLoading ? <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', margin: '0 0px -3px' }} animation="border" /> : 'Active'}</small>
                              </Tag>
                              <Tag style={{ margin: 0 }} className="booksTag" color={item.admin === 1 ? '#72c585' : '#eb8188'}
                                onClick={() => item.email !== 'admin@admin.com' && updateUser({
                                  id: item.id,
                                  admin: item.admin ? 0 : 1,
                                  admin_update: 'admin_update',
                                  userId: userId,
                                  userToken: userToken,
                                  userRole: userRole
                                })}
                              >
                                <small>{updateresult?.isLoading ? <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', margin: '0 0px -3px' }} animation="border" /> : 'Admin'}</small>
                              </Tag>
                            </div>
                          </td>
                        </tr>
                      )
                      }
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
