import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { productsApi } from '../services/products';
import { categoriesApi } from '../services/categories';
import { UsersApi } from '../services/users';
import { teamApi } from '../services/team';
import { MembershipApi } from '../services/membership';
import { loginApi } from '../services/login';
import { staticsApi } from '../services/statics';
import { booksApi } from '../services/books';
import { bookItemsApi } from '../services/bookItems';
import { listApi } from '../services/listing';
import { mediaApi } from '../services/media';
import { litMediaApi } from '../services/litMedia';
import { countriesApi } from '../services/countries';
import { integratedApi } from '../services/integrated';
import authReducer from "../reducers/authReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer,
    [MembershipApi.reducerPath]: MembershipApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [staticsApi.reducerPath]: staticsApi.reducer,
    [booksApi.reducerPath]: booksApi.reducer,
    [bookItemsApi.reducerPath]: bookItemsApi.reducer,
    [listApi.reducerPath]: listApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [integratedApi.reducerPath]: integratedApi.reducer,
    [litMediaApi.reducerPath]: litMediaApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productsApi.middleware).concat(categoriesApi.middleware).concat(UsersApi.middleware).concat(MembershipApi.middleware).concat(loginApi.middleware).concat(staticsApi.middleware).concat(booksApi.middleware).concat(bookItemsApi.middleware).concat(listApi.middleware).concat(mediaApi.middleware).concat(teamApi.middleware).concat(countriesApi.middleware).concat(integratedApi.middleware).concat(litMediaApi.middleware)
})
setupListeners(store.dispatch)
