import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Login from './components/Login';
import Profile from './components/Profile';
import Books from './components/Books';
import BookDetails from './components/BookDetails';
import BookTracking from './components/BookTracking';
import BookTasks from './components/BookTasks';
import Categories from './components/Categories';
import ChangePassword from './components/ChangePassword';
import Membership from './components/Membership';
import Language from './components/Language';
import Countries from './components/Countries';
import Admin from './Private/admin';
import Media from './components/Media';
import Task from './Private/task';
import AI from "./components/AI";
import Team from "./components/Team";
import Search from "./components/Books/Search";
import LanguageSelect from './LanguageSelect';
import NotFound from './NotFound';
import './App.css';
import { useSelector } from 'react-redux'

function App() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  return (
    <div className="App">

      <Router>
        <Switch>
          <Route exact path="/">
            {/* <BookTasks /> */}
            <Admin component={BookTasks} />
          </Route>
          <Route exact path="/books">
            {/* <Books /> */}
            <Admin component={Books} />
          </Route>

          <Route exact path="/media">
            {/* <Media /> */}
            <Admin component={userRole === 'admin' ? Media : NotFound} />
          </Route>
          
          <Route exact path="/book-tracking">
            {/* <BookTracking /> */}
            <Admin component={BookTracking} />
          </Route>
          <Route exact path="/book-tasks">
            {/* <BookTasks /> */}
            <Admin component={userRole === 'admin' ? BookTasks : NotFound} />
          </Route>
          <Route exact path="/book-details/:bookId/:itemId/:language/:title">
            {/* <BookDetails /> */}
            <Admin component={userRole === 'admin' ? BookDetails : NotFound} />
          </Route>

          <Route exact path="/countries">
            {/* <BookDetails /> */}
            <Admin component={userRole === 'admin' ? Countries : NotFound} />
          </Route>

          <Route exact path="/search/:name">
            {/* <Search /> */}
            <Admin component={userRole === 'admin' ? Search : NotFound} />
          </Route>

          <Route exact path="/categories">
            {/* <Categories /> */}
            <Admin component={userRole === 'admin' ? Categories : NotFound} />
          </Route>
          <Route exact path="/change-password">
            {/* <ChangePassword /> */}
            <Admin component={userRole === 'admin' ? ChangePassword : NotFound} />
          </Route>
          <Route exact path="/users">
            {/* <Users /> */}
            <Admin component={userRole === 'admin' ? Users : NotFound} />
          </Route>
          <Route exact path="/ai">
            {/* <AI /> */}
            <Admin component={userRole === 'admin' ? AI : NotFound} />
          </Route>
          <Route exact path="/team">
            {/* <Users /> */}
            <Admin component={userRole === 'admin' ? Team : NotFound} />
          </Route>
          <Route exact path="/membership">
            {/* <Membership /> */}
            <Admin component={userRole === 'admin' ? Membership : NotFound} />
          </Route>
          <Route exact path="/profile">
            {/* <Profile /> */}
            <Admin component={Profile} />
          </Route>

          <Route exact path="/login">
            <Login />
          </Route>

          <Route exact path="/language">
            {/* <Language /> */}
            <LanguageSelect cmplng={Language} />
          </Route>

          <Route>
            {/* <NotFound /> */}
            <Admin component={NotFound} />
          </Route>
 
        </Switch>
      </Router>

    </div>
  );
}

export default App;
