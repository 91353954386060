import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllCountriesQuery, useDeleteCountriesMutation, useCreateCountriesMutation, useUpdateCountriesMutation } from '../../services/countries';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function Countries() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const suppliersData = useGetAllCountriesQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.countries;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteCountriesMutation()
  const [createNew, createresult] = useCreateCountriesMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getCode, setCode] = useState("")
  const [getCurrency, setCurrency] = useState("")
  const [getConversion, setConversion] = useState("")
  const [getDiscount, setDiscount] = useState("")
  const [getPrice, setPrice] = useState("")
  const [getSubscriptions, setSubscriptions] = useState("")

  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setCode(data.code)
    setCurrency(data.currency)
    setConversion(data.conversion)
    setDiscount(data.discount)
    setPrice(data.price)
    setSubscriptions(data.subscriptions)
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    code: getCode,
    currency: getCurrency,
    conversion: getConversion,
    discount: getDiscount,
    price: getPrice,
    subscriptions: getSubscriptions,
    userId: userId,
    userToken: userToken,
    admin: admin
  }
  const [updatePost, updateresult] = useUpdateCountriesMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
      setChecked([])
    }
    if (createresult?.isSuccess) {
      setShow(false)
      setChecked([])
    }
  }, [updateresult, createresult]);






  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  const [checked, setChecked] = React.useState([]);

  const handleChange = (data) => {
    setChecked(data);
  };



  const [getSubscriptionsModal, setSubscriptionsModal] = React.useState(false);
  const [getSubscriptionsData, setSubscriptionsData] = React.useState([]);
  function showSubscription(data) {
    setSubscriptionsModal(true);
    setSubscriptionsData(data);
  }
  function closeSubscriptionsModal() {
    setSubscriptionsModal(false);
  }



  return (
    <div className="tabel_cards">

      <Modal className="new_Modal" show={getSubscriptionsModal} onHide={closeSubscriptionsModal} centered animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeSubscriptionsModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Subscriptions</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit(createNew)}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  {/* {getSubscriptionsData} */}
                  <pre>{getSubscriptionsData}</pre>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Body> 
        <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleClose}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>New</h5>
            </div>
          </div>

          <form onSubmit={handleSubmit(createNew)}>
            <div className="row">
              <input type="hidden" id="userId" defaultValue={userId} aria-invalid={errors.userId ? "true" : "false"}
                {...register('userId', { required: false })} />
              <input type="hidden" id="userToken" defaultValue={userToken} aria-invalid={errors.userToken ? "true" : "false"}
                {...register('userToken', { required: false })} />
              <input type="hidden" id="admin" defaultValue={admin} aria-invalid={errors.admin ? "true" : "false"}
                {...register('admin', { required: false })} />
              <div className="col-md-6">
                <div className="form-group">
                  <label>Country Name</label>
                  <input type="text" className="form-control" id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Code</label>
                  <input type="text" className="form-control" id="code"
                    aria-invalid={errors.code ? "true" : "false"}
                    {...register('code', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Currency</label>
                  <input type="text" className="form-control" id="currency"
                    aria-invalid={errors.currency ? "true" : "false"}
                    {...register('currency', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Conversion</label>
                  <input type="number" className="form-control" id="conversion"
                    aria-invalid={errors.conversion ? "true" : "false"}
                    {...register('conversion', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Discount</label>
                  <input type="number" className="form-control" id="discount"
                    aria-invalid={errors.discount ? "true" : "false"}
                    {...register('discount', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Price</label>
                  <input type="number" className="form-control" id="price"
                    aria-invalid={errors.price ? "true" : "false"}
                    {...register('price', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Subscriptions in JSON</label>
                  <textarea style={{ height: '300px' }} type="text" className="form-control" id="subscriptions"
                    aria-invalid={errors.subscriptions ? "true" : "false"}
                    {...register('subscriptions', { required: true })} >
                  </textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">

                  <Button
                    color="info"
                    variant="contained"
                    size="medium"
                    disabled={createresult?.isLoading ? true : false}
                    type="submit"
                    style={{ width: '100%', height: '45px', background: '#2c2245cf', color: '#f7f7f7' }}
                  >
                    {createresult?.isLoading ? 'loading...' : 'Create'}
                  </Button>

                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Body>
        <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleCloseEdit}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Country Name</label>
                <input type="text" className="form-control" value={getName}
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Code</label>
                <input type="text" className="form-control" value={getCode}
                  onChange={(e) => setCode(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Currency</label>
                <input type="text" className="form-control" value={getCurrency}
                  onChange={(e) => setCurrency(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Conversion</label>
                <input type="number" className="form-control" value={getConversion}
                  onChange={(e) => setConversion(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Discount</label>
                <input type="number" className="form-control" value={getDiscount}
                  onChange={(e) => setDiscount(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Price</label>
                <input type="text" className="form-control" value={getPrice}
                  onChange={(e) => setPrice(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Subscriptions in JSON</label>
                <textarea style={{ height: '300px' }} type="text" className="form-control" onChange={(e) => setSubscriptions(e.target.value)} >
                  {getSubscriptions}
                </textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px', background: '#2c2245cf', color: '#f7f7f7' }}
                  disabled={updateresult?.isLoading ? true : false}
                  onClick={() => { updatePost(updateData) }}>
                  {updateresult?.isLoading ? 'loading...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="storeCardB">
                <div className="updateButton">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ position: 'relative', marginRight: '10px' }}>
                      <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                      <SearchIcon className="table_search_icon" />
                    </div>
                  </div>

                  <div className="table_search_card">
                    <div>
                      <Button
                        style={{ marginRight: '10px' }}
                        disabled={checked?.event ? false : true}
                        variant="outlined"
                        size="small"
                        className="mr10"
                        onClick={() => deleteList({
                          status: checked?.status === 0 ? 1 : 0,
                          id: checked?.id,
                          userId: userId,
                          userToken: userToken,
                          admin: admin
                        })}
                      >
                        {deleteresult?.isLoading && deleteresult?.originalArgs?.id === checked?.id ?
                          <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          :
                          'Delete'
                        }
                      </Button>
                      <Button
                        style={{ marginRight: '10px' }}
                        disabled={checked?.event ? false : true}
                        variant="outlined"
                        size="small"
                        className="mr10"
                        onClick={() => handleOpenEdit({
                          status: true,
                          name: checked?.name,
                          code: checked?.code,
                          currency: checked?.currency,
                          conversion: checked?.conversion,
                          discount: checked?.discount,
                          price: checked?.price,
                          subscriptions: checked?.subscriptions,
                          id: checked?.id
                        })}
                      >
                        Edit
                      </Button>
                      <Button
                        color="warning"
                        variant="contained"
                        size="small"
                        onClick={() => handleOpen(true)}>
                        Add New
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    shape="rounded"
                    disabled={suppliersData?.isLoading ? true : false}
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="tabelOverflow">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Currency</th>
                      <th>Conversion</th>
                      <th>Discount</th>
                      <th>Price</th>
                      <th>Subscriptions</th>

                      <th className="sticky-column" style={{ textAlign: 'right', width: '1px' }}>
                        Created
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {suppliersData?.isLoading &&
                      <Skeletons />
                    }
                    {suppliers?.map((item, i) =>
                      <tr>
                        <td className="Order-Table_item text" style={{ width: '0px' }}>
                          <Checkbox
                            size="small"
                            checked={checked?.id === item.id ? checked?.event : false}
                            onChange={((event) => handleChange({
                              event: event.target.checked,
                              name: item.name,
                              code: item.code,
                              currency: item.currency,
                              conversion: item.conversion,
                              discount: item.discount,
                              price: item.price,
                              subscriptions: item.subscriptions,
                              id: item.id
                            }))}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </td>
                        <td className="Order-Table_item text">
                          {item.name}
                        </td>
                        <td className="Order-Table_item text">{item.code}</td>
                        <td className="Order-Table_item text">{item.currency}</td>
                        <td className="Order-Table_item text">{item.conversion}</td>
                        <td className="Order-Table_item text">{item.discount}</td>
                        <td className="Order-Table_item text">{item.price}</td>
                        <td className="Order-Table_item text">
                          {item.subscriptions &&
                            <Button
                              color="info"
                              variant="contained"
                              size="small"
                              onClick={() => showSubscription(item.subscriptions)}>
                              <small>Show</small>
                            </Button>
                          }
                        </td>
                        <td className="Order-Table_item text sticky-column">
                          <td className="dFlex center nowrap jcr">
                            {moment(item.createdAt).local().format("MMM Do YYYY")}
                          </td>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {suppliers?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Countries;
