import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { Spinner } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import UsersSkeleton from "./UsersSkeleton";
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/users';
import { Tag } from "antd";
import Moment from 'moment';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

function Users() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const [getSearchId, setSearchId] = useState("")
  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.users;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;


  const [updateUser, updateresult] = useUpdateUserMutation()


  return (
    <div className="tabel_cards">


      <div>
        <div className="row card-list-row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>

              <div className="storeCardB">
                <div className="updateButton">
                  <div style={{ position: 'relative' }}>
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                    {usersInfo?.isFetching &&
                      <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                    }
                    <SearchIcon className="table_search_icon" />
                  </div>

                </div>

                <div className="newFlexPagi">
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>

                  <Pagination
                    className='list-paginatiion-table'
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    disabled={usersInfo?.isLoading ? true : false}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{
                          pathname: `${window.location.pathname}`,
                          search: `?page=${parseInt(item.page)}`
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
                <div className="tabelOverflow">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Reference</th>
                        <th>language</th>
                        <th>Country</th>
                        <th>Mobile</th>
                        <th>Updated At</th>
                        <th className="sticky-column" style={{ textAlign: 'right' }}>
                          Status
                        </th>

                      </tr>
                    </thead>
                    {users?.length === 0 ?
                      <h5 className="data_not-found">No data available</h5>
                      : null}
                    <tbody>
                      <>
                        {usersInfo?.isLoading &&
                          <UsersSkeleton />
                        }
                      </>
                      {users?.map((item, i) =>
                        <tr key={i}>
                          <td>
                            <div>{item.id}</div>
                          </td>
                          <td>
                            <div>
                              <div> {item.name && item.name + ', '}{item.email ? item.email : item.username}</div>
                            </div>
                          </td>
                          <td>
                            {item.reference &&
                              <Tag className="booksTag" color={"#55acee"}>
                                <small>{item.reference}</small>
                              </Tag>
                            }
                          </td>
                          <td>
                            <div>{item.language}</div>
                          </td>
                          <td>
                            <div>{item.country}</div>
                          </td>
                          <td>
                            <div>{item.phone}</div>
                          </td>
                          <td>
                            <div>{Moment(item.createdAt).format('DD-MM-YYYY')}</div>
                          </td>
                          <td className="sticky-column" style={{ textAlign: 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                              {item.state &&
                                <Tag className="booksTag" color={"#7dc487"}>
                                  <small>{item.state}</small>
                                </Tag>
                              }
                              <Tag style={{ margin: 0 }} className={item.status === 1 ? "booksTag" : "booksTagE"} color={item.status === 1 ? '#72c585' : '#eb8188'}
                                onClick={() => item.email !== 'admin@admin.com' && updateUser({
                                  id: item.id,
                                  status: item.status ? 0 : 1,
                                  status_update: 'status_update',
                                  userId: userId,
                                  userToken: userToken,
                                  userRole: userRole
                                })}
                              >
                                <small>
                                  {updateresult?.isLoading && updateresult?.originalArgs?.id === item.id ?
                                    <Spinner
                                      style={{ width: '1rem', height: '1rem', fontSize: '7px', margin: '0 0px -3px' }}
                                      animation="border" /> :
                                    'Active'
                                  }
                                </small>
                              </Tag>
                            </div>
                          </td>
                        </tr>
                      )
                      }
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
