import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function ImageUpdate(props) {

  return (
    <>
      <Modal className="image-update-modal" show={props.getImageUpdateModal} onHide={props.closeImageUpdate} animation={false}>

        {/* <Modal.Header closeButton>
          <Modal.Title>Update Image</Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={props.closeImageUpdate}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Image</h5>
            </div>
          </div>
          <div>{props.getImageError && props.getImageError}</div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input type="file" className="form-control"
                  onChange={(e) => props.setUpdateImage(e.target.files[0])} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Button
                  style={{ background: '#2c2245cf', color: '#f7f7f7' }}
                  disabled={props.imageresult.isLoading ? true : false}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={props.updateBookImage}
                >
                  {props.imageresult.isLoading ? 'Updating...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageUpdate;
