import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { Table, Spinner, Accordion, Card, Dropdown, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux'

function UpdateBookCover({ data, show, close, error, isLoading, isSuccess, updateResult }) {
  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const location = useLocation();

  // create new book cover
  const [getBookCoverTitle, setBookCoverTitle] = useState('')
  const bookCoveeData = {
    title: getBookCoverTitle,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    id: data.updateId
  }

  return (
    <Modal className="new-book-Cover" show={show} onHide={close} animation={false}>
      {/* <Modal.Header closeButton>
        <Modal.Title>Update</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>

        <IconButton
          style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
          onClick={close}
        >
          <CloseIcon className="Header-close-modal-icon" />
        </IconButton>
        <div className="row">
          <div className="col-md-12">
            <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Cover</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {error &&
              <div className="alert alert-danger" style={{ padding: '10px', textAlign: 'center' }}>{error}</div>
            }
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control" defaultValue={data.title}
                onChange={(e) => setBookCoverTitle(e.target.value)} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <Button
                style={{ width: '100%', background: '#2c2245cf', color: '#f7f7f7' }}
                disabled={isLoading ? true : false}
                color="info"
                variant="contained"
                size="medium"
                onClick={() => { updateResult(bookCoveeData) }}>
                {isLoading ? 'Loading...' : 'Submit'}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
}

export default UpdateBookCover;
