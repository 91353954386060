import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateImageMutation } from '../../services/bookItems';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
function ImageUpdate({ getImageUpdateModal, setImageUpdateModal, getImageUpdateData }) {
  const { userId, userToken, userEmail, userName, status, userRole, languageCode } = useSelector((state) => state.auth);
  const [getUpdateImage, setUpdateImage] = useState("");
  const imageData = {
    imageData: getUpdateImage
  }

  const [updateImage, imageresult] = useUpdateImageMutation()
  const [getImageError, setImageError] = useState("");
  function updateBookImage(data) {
    if (data.imageData.size < 1000000 && (data.imageData.type === "image/png" || data.imageData.type === "image/jpeg") ||
      data.imageData.type === "image/jpg") {
      updateImage({
        image_file: data.imageData,
        book_id: getImageUpdateData?.book_id,
        id: getImageUpdateData?.id,
        language: getImageUpdateData?.language,
        userId: userId,
        userToken: userToken,
        userRole: userRole,
      })
    } else {
      setImageError('Please select the correct image type.')
    }
  }
  useEffect(() => {
    if (imageresult.isSuccess) {
      setImageUpdateModal(false)
    }
  }, [imageresult]);

  function closeImageUpdate() {
    setImageUpdateModal(false)
  }

  return (
    <>
      <Modal className="image-update-modal" show={getImageUpdateModal} onHide={closeImageUpdate} animation={false}>
        {/* <div className="close-button" onClick={props.closeImageUpdate} ><CloseIcon /></div> */}
        {/* <Modal.Header closeButton>
          <Modal.Title><h5>Update image</h5></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeImageUpdate}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Image</h5>
            </div>
          </div>


          <div>{getImageError && getImageError}</div>
          <div className="row">

            <div className="col-md-12">
              <div className="form-group">
                <input type="file" className="form-control"
                  onChange={(e) => setUpdateImage(e.target.files[0])} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  style={{ width: '100%', background: '#2c2245cf', color: '#f7f7f7' }}
                  disabled={imageresult?.isLoading ? true : false}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={() => updateBookImage(imageData)}>
                  {imageresult?.isLoading ? 'Loading...' : 'Submit'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageUpdate;
