import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { Table, Spinner, Accordion, Card, Dropdown, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useGetAllCategoryQuery } from '../../services/categories';

function NewBookItem({ show, close, error, isLoading, isSuccess, createBookItem, getBookCoverId, getItemLanguages, getBookTitle }) {
  const { userId, userToken, isAdmin, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const location = useLocation();



  const [getTitle, setTitle] = useState("")
  const [getSubTitle, setSubTitle] = useState("")
  const [getIsbn, setIsbn] = useState("")
  const [getAuthor, setAuthor] = useState("")
  const [getDetails, setDetails] = useState("")
  const [selectedOption, setSelectedOption] = useState(null);
  const [geletedLanguage, seletedLanguage] = useState("")
  const [getPostType, setPostType] = useState("")
  const [getCategory, setCategory] = useState("")
  const bookItemData = {
    title: getTitle,
    subtitle: getSubTitle,
    isbn: getIsbn,
    authors: getAuthor,
    details: getDetails,
    languageCode: geletedLanguage,
    media_type: getPostType,
    category: getCategory,
    book_id: getBookCoverId,
    tags: selectedOption?.map(item => item.value).join(', '),
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  }


  useEffect(() => {
    if (isSuccess) {
      setTitle('')
      setSubTitle('')
      setIsbn('')
      setAuthor('')
      setDetails('')
      setSelectedOption(null)
      seletedLanguage('')
    }
  }, [isSuccess]);


  // list all categories
  const categoriesData = useGetAllCategoryQuery({
    userId: userId,
    userToken: userToken,
    userRole: userRole,
    page: 1,
    size: 100,
    searchid: '',
    language: geletedLanguage ? geletedLanguage : null,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const categories = categoriesData?.data && categoriesData?.data?.categories;


  function renderList() {
    return (categories?.map(data => ({ label: data.title, value: data.title })))
  }

  let arrajasa = [];
  if (categories) {
    categories?.map(data =>
      arrajasa.push({ label: data.title, value: data.title })
    )
  }

  const withTitle = [{ label: getBookTitle, value: getBookTitle }];
  const listOfTags = [...withTitle, ...arrajasa];

  const selectLanguage = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'ar',
      name: 'العربية'
    },
    {
      code: 'desi',
      name: 'Desi'
    },
    {
      code: 'urdu',
      name: 'اردو'
    },
  ]
  const unaddedCartItems = selectLanguage?.filter(addedItem => {
    return !getItemLanguages?.find(cartItem => cartItem.language === addedItem.code);
  });

  let langDirection;

  if (geletedLanguage === 'ar') {
    langDirection = 'rtl'
  } else if (geletedLanguage === 'urdu') {
    langDirection = 'rtl'
  } else if (geletedLanguage === 'desi') {
    langDirection = 'ltr'
  } else if (geletedLanguage === 'en') {
    langDirection = 'ltr'
  } else {
    langDirection = 'ltr'
  }

  const customStyles = {
    control: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer',
      borderColor: 'hsl(0, 0%, 80%) !important',
      boxShadow: 'none !important',
      maxWidth: '100%'
    }),
    option: styles => ({
      ...styles,
      width: 'auto',
      cursor: 'pointer'
    }),
    menu: styles => ({
      ...styles,
      width: '100%'
    })
  };


  function buttonDisabled() {
    if (!getTitle) {
      return true
    } else if (!geletedLanguage) {
      return true
    } else if (isLoading) {
      return true
    } else {
      false
    }
  }


  return (
    <Modal className="new-book-item" show={show} onHide={close} animation={false}>
      {/* <Modal.Header closeButton style={{ alignItems: 'center' }}>
        <Modal.Title>Add New Book</Modal.Title>
      </Modal.Header> */}
      <Modal.Body dir={langDirection}>

        <IconButton
          style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
          onClick={close}
        >
          <CloseIcon className="Header-close-modal-icon" />
        </IconButton>
        <div className="row">
          <div className="col-md-12">
            <h5 style={{ textAlign: 'center', padding: '10px' }}>New Item</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {error &&
              <div className="alert alert-danger" style={{ padding: '10px', textAlign: 'center' }}>{error}</div>
            }
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Category</label>
              <select required className="form-control" onChange={(e) => setCategory(e.target.value)}>
                <option value={``} selected disabled>Select Category</option>
                <option value={`books`}>Books</option>
                <option value={`documentary`}>Documentary</option>
                <option value={`courses`}>Courses</option>
                <option value={`news`}>News</option>
                <option value={`ads`}>Ads</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Post Type</label>
              <select required className="form-control" onChange={(e) => setPostType(e.target.value)}>
                <option value={``} selected disabled>Select Media Type</option>
                <option value={`audio`}>Audio</option>
                <option value={`video`}>Video</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Language</label>
              <select required className="form-control" onChange={(e) => seletedLanguage(e.target.value)}>
                <option value='' selected disabled>Select Language</option>
                {unaddedCartItems?.map((item, i) =>
                  <option value={item.code}>{item.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Tags</label>
              <Select
                className='select-field'
                styles={customStyles}
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={listOfTags}
                isLoading={(!categoriesData?.isLoading) && (categoriesData?.isFetching) ? true : false}
                isMulti
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control"
                onChange={(e) => setTitle(e.target.value)} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Sub Title</label>
              <input type="text" className="form-control"
                onChange={(e) => setSubTitle(e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>ISBN Number</label>
              <input type="text" className="form-control"
                onChange={(e) => setIsbn(e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Author</label>
              <input type="text" className="form-control"
                onChange={(e) => setAuthor(e.target.value)} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Details</label>
              <textarea className="form-control"
                style={{ minHeight: '120px' }}
                onChange={(e) => setDetails(e.target.value)}>
              </textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group" style={{ textAlign: 'right' }}>
              <Button
                style={{ background: '#2c2245cf', color: '#f7f7f7' }}
                disabled={buttonDisabled()}
                color="info"
                variant="contained"
                size="medium"
                onClick={() => { createBookItem(bookItemData) }}>
                {isLoading ? 'Loading...' : 'Submit'}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
}

export default NewBookItem;
