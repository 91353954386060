import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import { addToLanguage } from "../../reducers/authReducer";
function Language() {
  const history = useHistory();
  const dispatch = useDispatch();

  function selectlanguage(data) {
    dispatch(addToLanguage(data));
    history.push('/');
  }

  return (
    <div className="language-select">
      <div className="container">
        <div className="row loginRow">
          <div className="rowBox">
            <div className="loginHeader">
              <h3>Select the Language</h3>
            </div>
            <br />
            <div className="Language-select-card">
              <div className="col-md-3">
                <div className="language-box" onClick={() => { selectlanguage('en') }}>
                  <div>English</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="language-box" onClick={() => { selectlanguage('ar') }}>
                  <div>Arabic</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="language-box" onClick={() => { selectlanguage('desi') }}>
                  <div>Desi</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="language-box" onClick={() => { selectlanguage('urdu') }}>
                  <div>Urdu</div>
                </div>
              </div>
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;
