import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
// import { useUpdateAudioMutation } from '../../services/bookItems';
import { useUpdateAudioMutation } from '../../services/litMedia';

function AddMdia({ id, book_id, book_item_id, book_language, media_type, audio }) {
  const { userId, userToken, userEmail, userName, status, userRole, languageCode } = useSelector((state) => state.auth);

  const [updateAudio, audioresult] = useUpdateAudioMutation()
  const [getAudioFile, setAudioFile] = useState("");
  const [getaudioError, setaudioError] = useState("");

  function updateNewMedia() {
    // if (getAudioFile?.size < 500000000) {
    updateAudio({
      audio_file: getAudioFile,
      book_id: book_id,
      language: book_language,
      book_item_id: book_item_id,
      id: id,
      media_type: media_type,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
    })
    // } else {
    //   setaudioError('Please select the correct size.')
    // }
  }

  const handleChangeStatus = ({ meta, file }, status) => {
    setAudioFile(file)
  };



  return (
    <>
      <div>{getaudioError && getaudioError}</div>
      <div className="row summary-media-section">
        {!audio &&
          <Dropzone
            onChangeStatus={handleChangeStatus}
            onSubmit={updateNewMedia}
            accept="audio/*,video/*"
            maxFiles="1"
            disabled={audioresult.isLoading ? true : false}
            submitButtonDisabled={audioresult.isLoading ? true : false}
            inputWithFilesContent={`DRAG FILES OR CLICK TO BROWSE`}
            submitButtonContent={
              <div>
                {audioresult.isLoading ?
                  <span style={{ fontSize: '12px', color: '#fff' }}>Loading...</span>
                  :
                  <span>Submit</span>
                }
              </div>
            }
          />
        }

      </div>
    </>
  );
}

export default AddMdia;
