import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Navbar, Button } from "react-bootstrap";
import "./index.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { Menu, Dropdown } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { removeToLanguage, removeToCart } from "../../reducers/authReducer";
import CategoryIcon from '@mui/icons-material/Category';
import PeopleIcon from '@mui/icons-material/People';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import MovieIcon from '@mui/icons-material/Movie';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@mui/material/IconButton';
import PublicIcon from '@mui/icons-material/Public';
import ApiIcon from '@mui/icons-material/Api';

import { useForm } from "react-hook-form";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
function HeaderMenu(props) {
  const dispatch = useDispatch();
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  function handleClick(e) {
    console.log('', e);
  }
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();
  const location = useLocation();


  function userLogout() {
    dispatch(removeToCart());
    history.push(`/`);
  }

  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };




  const menu = (
    <Menu className="Header-dropdown-menu">
      <Menu.Item key="002">
        <Link to="#" style={{ fontSize: '16px' }}>Settings</Link>
      </Menu.Item>
      <Menu.Item key="004">
        <Link to="#" style={{ fontSize: '16px' }} onClick={userLogout}>Sign out</Link>
      </Menu.Item>
    </Menu>
  );


  function displayMenu() {
    const getName = new URLSearchParams(location.search).get('name')
    return (
      <div className="dFlex">
        <div className="dFlex" style={{ width: '100%', whiteSpace: 'pre', fontSize: '1.1rem', paddingLeft: '0px', textTransform: 'capitalize', color: '#9f9f9f' }}>
          <span>{
            window.location.pathname.split('-').join(' ').split('/')[1] ?
              window.location.pathname.split('-').join(' ').split('/')[1] :
              'Board'
          }</span>
          <small style={{ display: 'flex', alignItems: 'center', color: '#838383' }}>
            {getName && <><KeyboardArrowRightIcon /> {getName}</>}
          </small>
        </div>
      </div>
    )
  }


  function onSubmit(data) {
    history.push(`/search/${data.emailid}`);
    reset()
  }


  return (
    <div className="ML-Header">

      <Menu onClick={handleClick} mode="vertical"
        inlineCollapsed={props.collapsed} className="sideMenuN">
        <div className={`navTophdng strNumnv logoHeader ${props.collapsed && 'navTophdngcollapsed'}`}>
          <span className="nameColor"><span className={`header_top-heading ${props.collapsed && 'hideHeaderName'}`}>
            <img src="https://cdn.ebtida.com/1691513609708-LiT_logo-white.png" style={{ width: '60px', filter: 'contrast(0.4)' }} />
          </span>
            <span className={`syncIcon ${props.collapsed && 'syncColor'}`} onClick={() => props.toggleCollapsed({ toggleCollapsed })}>
              {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </span>
          </span>
        </div>
        <Menu.Item className="menuColor dFlex" key="01" icon={<DashboardIcon className="sideMenuIcon" />}>
          <Link to={{ pathname: "/", state: { from: "root" } }}>
            Board
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="1112" icon={<PublicIcon className="sideMenuIcon" />}>
          <Link to={{ pathname: "/countries", state: { from: "root" } }}>
            Countries
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="03" icon={<MenuBookIcon className="sideMenuIcon" />}>
          <Link to={{ pathname: "/books", state: { from: "root" } }}>
            Books
          </Link>
        </Menu.Item>
        {userRole === 'admin' &&
          <>
            <Menu.Item className="menuColor dFlex" key="02" icon={<CategoryIcon className="sideMenuIcon" />}>
              <Link to={{ pathname: "/categories", state: { from: "root" } }}>
                Categories
              </Link>
            </Menu.Item>

          </>
        }
        {userRole === 'admin' &&
          <>
            <Menu.Item className="menuColor dFlex" key="066" icon={<PeopleAltIcon className="sideMenuIcon" />}>
              <Link to={{ pathname: "/team", state: { from: "root" } }}>
                Team
              </Link>
            </Menu.Item>
            <Menu.Item className="menuColor dFlex" key="07" icon={<CardMembershipIcon className="sideMenuIcon" />}>
              <Link to={{ pathname: "/membership", state: { from: "root" } }}>
                Membership
              </Link>
            </Menu.Item>
            <Menu.Item className="menuColor dFlex" key="08" icon={<MovieIcon className="sideMenuIcon" />}>
              <Link to={{ pathname: "/media", state: { from: "root" } }}>
                Media
              </Link>
            </Menu.Item>
            <Menu.Item className="menuColor dFlex" key="06" icon={<PeopleIcon className="sideMenuIcon" />}>
              <Link to={{ pathname: "/users", state: { from: "root" } }}>
                Users
              </Link>
          </Menu.Item>
          <Menu.Item className="menuColor dFlex" key="060" icon={<ApiIcon className="sideMenuIcon" />}>
              <Link to={{ pathname: "/ai", state: { from: "root" } }}>
                AI
              </Link>
            </Menu.Item>
            {/* <SubMenu key="submenu-item" className="menuColor" icon={<SettingsIcon />} title="Settings">
              <Menu.Item className="subMenuColor" key="submenu-item-3">
                <Link to="#" onClick={userLogout} >
                  Logout
                </Link>
              </Menu.Item>
            </SubMenu> */}
          </>
        }
      </Menu>
      <Navbar className={`topNavBar ${props.collapsed && 'menuCollapsed'}`} bg="white" expand="lg">
        <div className="addAllIconCard hideOnMob">
          {displayMenu()}
        </div>
        <div className="headerRightCard">
            <form onSubmit={handleSubmit(onSubmit)} className="table_search_card">
              <input
                type="text"
                className="form-control table_search"
                placeholder="Search for book..."
                id="emailid"
                aria-invalid={errors.emailid ? "true" : "false"}
                {...register("emailid", { required: true })}
              />
              <IconButton aria-haspopup="true" type="submit" className="table_search_icon">
                <SearchIcon style={{fontSize: 'inherit'}} />
              </IconButton>
            </form>
        
          <Dropdown className="Header-waller-connect2" overlay={menu} trigger={['click']}>
            <IconButton aria-haspopup="true">
              {/* <SettingsIcon style={{ color: '#1e2f438f' }} /> */}
              <img src='https://lit.ebtida.com/user-thumbnail.png' style={{width: '30px', borderRadius: '100px'}} />
            </IconButton>
          </Dropdown>
        </div>
      </Navbar>
    </div>
  );
}

export default HeaderMenu;
